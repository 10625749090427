import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";
const LinkedInPost = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const [input, setInput] = useState("");
  const [paragraphs, setParagraphs] = useState(6);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  const outputRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };
  const clickHandle = async () => {
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Write a linked in post for the topic of: "${input}" of medium size `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };
  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          LinkedIn Post Generator - Create Professional LinkedIn Posts Easily
        </title>
        <link
          rel="canonical"
          href="http://toolsbag.io/AiTools/LinkedInPostGenerator"
        />
        <meta
          name="description"
          content="Generate professional LinkedIn posts online. Perfect for sharing insights, achievements, or announcements with your network."
        />
        <meta
          name="keywords"
          content="LinkedIn post generator, create LinkedIn posts, professional posts, LinkedIn content, social media post generator, generate LinkedIn updates, LinkedIn marketing, LinkedIn writing tool, professional networking, engaging LinkedIn posts, content creation for LinkedIn, business networking, LinkedIn article ideas, enhance LinkedIn presence, share achievements on LinkedIn, LinkedIn engagement, network building, LinkedIn storytelling, crafting LinkedIn posts, professional branding, social media strategies, LinkedIn content creation, thought leadership on LinkedIn, effective LinkedIn communication, career development posts, share insights on LinkedIn, promote your business on LinkedIn, increase LinkedIn visibility, LinkedIn content planner, networking tips on LinkedIn, company announcements on LinkedIn, share knowledge on LinkedIn, career advice posts, LinkedIn for professionals, online reputation management, content sharing on LinkedIn, build a personal brand, share success stories, LinkedIn branding strategies, industry insights, professional growth posts, meaningful connections on LinkedIn, LinkedIn influence, generate connections, enhance professional image, write engaging LinkedIn posts, informative LinkedIn updates, LinkedIn networking strategies, improve LinkedIn interactions, showcase skills on LinkedIn, attract followers on LinkedIn, LinkedIn profile optimization,
     company culture posts, promote your services, online presence management, career journey posts, job seeking tips, share professional experiences, content ideas for LinkedIn, share expertise, LinkedIn for career growth, promote thought leadership, build professional relationships, showcase achievements, LinkedIn marketing tips, increase audience engagement, valuable LinkedIn content, LinkedIn strategy development, share events on LinkedIn, highlight industry trends, LinkedIn community building, build trust on LinkedIn, share business updates, professional development content, create impactful posts, connect with industry leaders, promote your brand, generate leads on LinkedIn, LinkedIn content suggestions, personal branding on LinkedIn, effective social media posts, meaningful interactions, LinkedIn networking events, showcase projects on LinkedIn, share opinions on LinkedIn, share your vision, enhance online presence, LinkedIn content strategy, professional engagement, building authority on LinkedIn, share insights and advice, write concise LinkedIn posts, LinkedIn for entrepreneurs, business growth posts, highlight key accomplishments, create discussions, attract business opportunities, connect with like-minded professionals, drive engagement, LinkedIn for small businesses, showcase team achievements, promote your expertise, highlight career milestones, LinkedIn content ideas, share industry knowledge, engage with your audience, create a positive impact, informative professional updates, connect with potential clients, demonstrate expertise, business storytelling on LinkedIn, create compelling narratives, network effectively, enhance professional credibility, share relevant content, promote company values, highlight industry knowledge, position yourself as an expert, foster community on LinkedIn, effective branding strategies, career promotion, showcase your work, create an active LinkedIn presence"
        />
      </Helmet>

      <div className="text-center">
        <h1 className="title_lg">Linked In Post Generater</h1>
        <p className="mt_5">Generater your LinkedIn Post </p>
      </div>
      <div
        className="summarizer"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Post's topic</h4>
              <p>What's your post of LinkedIn about?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/100
                  </span>
                </div>
                <textarea
                  ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="e.g. Software developer"
                  rows="15"
                  cols="100"
                  maxLength="100"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
        <Instructions
          description="  This tool helps you craft professional LinkedIn posts that effectively communicate your ideas, achievements, or announcements. Whether you want to share insights, promote content, or celebrate milestones, simply enter your topic, and the tool will generate a LinkedIn-worthy post for you."
          title="Generate a LinkedIn Post"
          step1="Enter the topic, achievement, or idea you want to share in the"
          step2="Click the Generate Post button to create a LinkedIn-ready post based on your input."
          step3=" Review the generated post, and make any necessary adjustments to suit your personal or company voice. Copy and paste the post into LinkedIn, and share it with your network!"
        />
      </div>
    </>
  );
};

export default LinkedInPost;
