import React, { useState, useRef, useEffect } from "react";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker.entry";
import { useLocation } from "react-router-dom";
import { PDFDocument } from "pdf-lib"; // Import PDF manipulation library
import Loader from "./Loader1";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";

const PDFPageDeleter = () => {
  const [pdfData, setPdfData] = useState(null); // Holds the original PDF data
  const [pageImages, setPageImages] = useState([]); // Stores the images of PDF pages
  const [pagesToDelete, setPagesToDelete] = useState([]); // Tracks selected pages for deletion
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPagesDeleted, setIsPagesDeleted] = useState(false); // New state to track deletion
  const fileInputRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Handle file upload
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFileName(selectedFile.name);
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        setPdfData(new Uint8Array(arrayBuffer)); // Store PDF data for future modification
        await renderPdfPages(arrayBuffer);
      };
      reader.readAsArrayBuffer(selectedFile);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  // Render the PDF pages as images
  const renderPdfPages = async (pdfArrayBuffer) => {
    const pdfDoc = await pdfjsLib.getDocument({ data: pdfArrayBuffer }).promise;
    const pageImages = [];
    for (let i = 1; i <= pdfDoc.numPages; i++) {
      const page = await pdfDoc.getPage(i);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      await page.render({ canvasContext: context, viewport }).promise;
      pageImages.push(canvas.toDataURL()); // Save page as an image
    }
    setPageImages(pageImages);
    setIsLoading(false);
  };

  // Toggle selection for pages to delete
  const togglePageSelection = (index) => {
    if (pagesToDelete.includes(index)) {
      setPagesToDelete(pagesToDelete.filter((page) => page !== index)); // Deselect the page
    } else {
      setPagesToDelete([...pagesToDelete, index]); // Select the page for deletion
    }
  };

  // Delete selected pages
  const deletePages = async () => {
    if (!pdfData) return;

    const originalPdfDoc = await PDFDocument.load(pdfData);
    const totalPages = originalPdfDoc.getPageCount();
    const newPdfDoc = await PDFDocument.create();

    for (let i = 0; i < totalPages; i++) {
      if (!pagesToDelete.includes(i)) {
        const [copiedPage] = await newPdfDoc.copyPages(originalPdfDoc, [i]);
        newPdfDoc.addPage(copiedPage);
      }
    }

    const modifiedPdfBytes = await newPdfDoc.save();
    setPdfData(modifiedPdfBytes); // Update pdfData with modified PDF
    setIsPagesDeleted(true); // Mark pages as deleted
  };

  // Download the modified PDF
  const downloadModifiedPdf = () => {
    if (!pdfData) return;

    const blob = new Blob([pdfData], { type: "application/pdf" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `modified_${fileName}`;
    downloadLink.click();
  };

  const resetForm = () => {
    setPdfData(null);
    setPageImages([]);
    setPagesToDelete([]);
    setFileName("");
    setIsPagesDeleted(false); // Reset the deletion state
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the file input only if it exists
    }
  };

  return (
    <>
      <Helmet>
  <meta charSet="utf-8" />
  <title>PDF Page Deleter - Delete Pages from PDF and Download</title>
  <link rel="canonical" href="http://toolsbag.io/PDFTools/pagedeleter" />
  <meta
    name="description"
    content="Easily delete specific pages from your PDF document online and download the modified version. Simple and fast PDF page deletion tool."
  />
  <meta
    name="keywords"
    content="PDF page deleter, delete pages from PDF, online PDF editor, PDF page removal, remove pages from PDF, PDF editor online, delete PDF pages tool, PDF page delete tool, online PDF tools, PDF modification, PDF editing tool, remove PDF pages, PDF page editing, delete specific PDF pages, PDF document editor, easy PDF editing, free PDF tools, PDF splitter, page remover PDF, PDF page organizer, PDF customization, PDF page selector, fast PDF editor, simple PDF page delete, delete PDF content"
  />
</Helmet>

      <div className="container">
        <div className="text-center">
          <h1 className="title_lg">PDF Page Deleter</h1>
          <p className="mt_5">Select and delete pages from your PDF</p>
        </div>
        {fileName ? (
          <strong>
            <p className="filename">{fileName}</p>
            <p className="mt_5">
              Click on pages from your PDF which you want to delete.
            </p>
          </strong>
        ) : (
          <div className="uploader-container uploader-container--pdf">
            <form className="file-upload-form">
              <label htmlFor="file" className="file-upload-label">
                <div className="file-upload-design">
                  <svg viewBox="0 0 640 512" height="1em">
                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                  </svg>
                  <p>Drag and Drop</p>
                  <p>or</p>
                  <span className="browse-button">Browse file</span>
                </div>
                <input
                  type="file"
                  id="file"
                  accept=".pdf"
                  className="mt-2 w-75"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
              </label>
            </form>
          </div>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          pageImages.length > 0 && (
            <div className="mt-4">
              <div className="row">
                {pageImages.map((url, index) => (
                  <div
                    key={index}
                    className={`col-md-4 mb-4 text-center ${
                      pagesToDelete.includes(index) ? "selected-page" : ""
                    }`}
                    onClick={() => togglePageSelection(index)}
                  >
                    <img
                      src={url}
                      alt={`Page ${index + 1}`}
                      className="img-fluid mb-2 image-container1"
                      style={{
                        maxWidth: "80%",
                        cursor: "pointer",
                        border: pagesToDelete.includes(index)
                          ? "3px solid red"
                          : "none",
                      }}
                    />
                    <p>{`Page ${index + 1}`}</p>
                  </div>
                ))}
              </div>
              <div className="text-center">
                {!isPagesDeleted ? (
                  <button
                    className="normal-button"
                    onClick={deletePages}
                  >
                    Delete Pages
                  </button>
                ) : (
                  <button
                    className="buttonDownload"
                    onClick={downloadModifiedPdf}
                  >
                    Download Modified PDF
                  </button>
                )}
              </div>
              <div className="text-center mt-3">
                <button
                  className="Reset-button"
                  onClick={resetForm}
                >
                  Upload New PDF
                </button>
              </div>
            </div>
          )
        )}
      </div>
      <Instructions
          description="This tool allows you to delete specific pages from your PDF files easily. Upload your PDF, select the pages you want to remove, and click Delete Pages to save your updated document."
          title="Delete Pages from Your PDF"
          step1="Upload the PDF file from which you want to delete pages."
          step2="Click on the pages you want to delete to select them. Selected pages will be highlighted."
          step3="Click Delete Pages to remove the selected pages from your PDF. and download it."
        />
    </>
  );
};

export default PDFPageDeleter;
