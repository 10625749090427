import React, { useState, useEffect } from "react";
import { Card, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./Loader1";
import { faUpload, faHistory } from "@fortawesome/free-solid-svg-icons";
import { compress } from "image-conversion";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
function CompressorComp() {
  const [compressedLink, setCompressedLink] = useState("");
  const [originalImage, setOriginalImage] = useState(null);
  const [originalLink, setOriginalLink] = useState("");
  const [uploadImage, setUploadImage] = useState(false);
  const [outputFileName, setOutputFileName] = useState("");
  const [compressionQuality, setCompressionQuality] = useState(0.8);
  const [originalSize, setOriginalSize] = useState(0);
  const [compressedSize, setCompressedSize] = useState(0);
  const [isCompressed, setIsCompressed] = useState(false);
  const [compressionInProgress, setCompressionInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [compressedHistory, setCompressedHistory] = useState([]);
  const [showCompressedImage, setShowCompressedImage] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (originalImage) {
      setCompressedLink("");
      setCompressedSize(0);
      setIsCompressed(false);
      setShowCompressedImage(false);
    }
  }, [originalImage]);
  async function uploadLink(event) {
    const imageFile = event.target.files[0];
    setOriginalLink(URL.createObjectURL(imageFile));
    setOriginalImage(imageFile);
    setOutputFileName(imageFile.name);
    setUploadImage(true);
    setOriginalSize(imageFile.size);
  }
  async function compressImage() {
    if (!originalImage) {
      alert("Please upload an image first.");
      return;
    }
    try {
      setCompressionInProgress(true);
      setShowCompressedImage(false);
      setLoading(true);
      const compressedImage = await compress(originalImage, {
        quality: compressionQuality,
        width: 800,
        height: 800,
      });
      setCompressedLink(URL.createObjectURL(compressedImage));
      setCompressedSize(compressedImage.size);
      setIsCompressed(true);
      setCompressedHistory([
        ...compressedHistory,
        {
          link: compressedLink,
          name: outputFileName,
        },
      ]);
      setTimeout(() => {
        setLoading(false);
        setShowCompressedImage(true);
      }, 2000);
    } catch (error) {
      console.error("Image compression failed:", error);
      alert("Image compression failed. Please try again.");
    } finally {
      setCompressionInProgress(false);
    }
  }

  function resetApp() {
    setOriginalLink("");
    setOriginalImage(null);
    setUploadImage(false);
    setOutputFileName("");
    setCompressionQuality(0.8);
    setOriginalSize(0);
    setCompressedSize(0);
    setIsCompressed(false);
    setCompressedLink("");
    setShowCompressedImage(false);
  }
  function toggleHistory() {
    setShowHistory(!showHistory);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Image Compressor - Compress and Optimize Images Online</title>
        <link
          rel="canonical"
          href="http://toolsbag.io/ImageTools/imageCompressor"
        />
        <meta
          name="description"
          content="Quickly compress and optimize your images online without losing quality. Perfect for web developers, photographers, and anyone needing smaller file sizes."
        />
        <meta
          name="keywords"
          content="image compressor, compress images, image optimization, reduce image size, online image compression, optimize images, image compressor, compress images, image optimization, reduce image size, online image compression, optimize images, compress photos, reduce photo size, image compression tool, shrink image size, image file compressor, photo compression tool, jpg compressor, png compressor, gif compressor, web image compressor, online photo compressor, image optimizer for web, reduce image size for web, compress images without losing quality, high-quality image compression, fast image compression, compress images for websites, compress images for SEO, image compressor for WordPress, compress images for Shopify, image compressor for WooCommerce, compress images for faster loading, compress images for mobile, compress images for responsive design, compress images for web developers, compress images for designers, compress images for photographers, compress images for social media, compress images for Facebook, compress images for Instagram, compress images for Twitter, image compression for blogs, image compression for e-commerce, compress images for email, compress images for web apps, compress images for PowerPoint, compress images for presentations, compress images for Google Slides, compress images for online portfolios,
           compress images for digital marketing, image compression for ads, compress images for banners, image compressor for flyers, image compressor for brochures, image compressor for posters, compress images for online galleries, compress images for digital art, compress images for Behance, compress images for Dribbble, compress images for real estate listings, compress images for architecture, compress images for floor plans, image compressor for interior design, image compressor for landscape design, compress images for engineering drawings, compress images for blueprints, compress images for CAD files, compress images for maps, compress images for scientific reports, compress images for research papers, compress images for data visualizations" />
      </Helmet>
      <div className="container">
      <div className="text-center">
        <h1 className="title_lg">Image Compressor</h1>
        <p className="mt_5">Compress the size of your images</p>
      </div>
      {!originalImage && (
            <div className="uploader-container uploader-container--images">
              <form className="file-upload-form">
                <label htmlFor="file" className="file-upload-label">
                  <div className="file-upload-design">
                    <svg viewBox="0 0 640 512" height="1em">
                      <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                    </svg>
                    <p>Drag and Drop</p>
                    <p>or</p>
                    <span className="browse-button">Browse file</span>
                  </div>
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    className="mt-2 btn btn-primary w-75"
                    onChange={(event) => uploadLink(event)}
                  />
                </label>
              </form>
        </div>
      )}
      <div className="mainContainer container my-2">
        <div className="text-center">
          <button
            type="button"
            className="normal-button mx-1"
            onClick={toggleHistory}
          >
            <FontAwesomeIcon icon={faHistory} className="icon" />
            History
          </button>
        </div>
        {showHistory && (
          <div className="history-container">
            <p>Compressed History:</p>
            <ul>
              {compressedHistory.map((item, index) => (
                <li key={index}>
                  <a href={item.link} download={item.name}>
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="row mt-5">
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            {uploadImage ? (
              <Card.Img
                className="image"
                variant="top"
                src={originalLink}
                alt="Original Image"
              />
            ) : (
              <Card.Img
                className="uploadCard"
                variant="top"
                src={faUpload}
                alt=""
              />
            )}
          </div>
          <div
            className="col-xl-6 col-lg-6 
						col-md-12 col-sm-12 
						d-flex justify-content-center 
						align-items-baseline"
          >
            <div className="container">
              {outputFileName ? (
                <div >
                  <label htmlFor="qualitySlider">Compression Quality:</label>
                  <input
                    id="qualitySlider"
                    type="range"
                    min="0.1"
                    max="1"
                    step="0.1"
                    value={compressionQuality}
                    onChange={(event) =>
                      setCompressionQuality(parseFloat(event.target.value))
                    }
                  />
                  <div className="text-center">
                    Original Size:
                    {Math.round(originalSize / 1024)} KB
                    <br />
                    Compressed Size:
                    {Math.round(compressedSize / 1024)} KB
                  </div>
                  <div className="text-center">
                    {isCompressed && !compressionInProgress && (
                      <div
                        className="text-success 
											compressed-message"
                      >
                        Image compressed successfully!
                      </div>
                    )}
                    {compressionInProgress && (
                      <div className="text-info">Compressing image...</div>
                    )}
                  </div>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    {loading ? (
                      <div className="text-info">Loading...</div>
                    ) : (
                      <button
                        type="button"
                        className="normal-button"
                        onClick={compressImage}
                      >
                        Compress
                      </button>
                    )}
                    <button
                      type="button"
                      className="Reset-button mx-1"
                      onClick={resetApp}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            {showCompressedImage ? (
              <div>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <Card.Img
                      className="image"
                      variant="top"
                      src={compressedLink}
                      alt="Compressed Image"
                      onClick={() => setModalShow(true)}
                      style={{ cursor: "pointer" }}
                    />
                    <a
                      href={compressedLink}
                      download={outputFileName}
                      className="mt-2 buttonDownload"
                    >
                      Download
                    </a>
                    <Modal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      size="lg"
                    >
                      <Modal.Body className="text-center">
                        <Card.Img
                          className="image"
                          variant="top"
                          src={compressedLink}
                          alt="Compressed Image"
                        />
                      </Modal.Body>
                      <Modal.Footer>
                      </Modal.Footer>
                    </Modal>
                  </>
                )}
              </div>
            ) : (
              <div
                className="d-flex align-items-center 
						justify-content-center"
              >
                {compressionInProgress && (
                  <Loader />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      </div>
      <Instructions
  description="This tool allows you to compress images, reducing file size while maintaining quality. Simply upload your image below, and the tool will optimize it for faster loading and storage."
  title="Compress Your Images"
  step1="Upload the image you want to compress."
  step2="The tool will automatically reduce the file size while preserving quality."
  step3="Once compressed, click Download Image to save the optimized image."
/>
    </>
  );
}

export default CompressorComp;
