import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "./SearchContext";
import text_svg from './pics/text.svg';
import image from "./pics/ima.svg"
import search from '../images/search.svg'
import youtube from './pics/youtube.svg';
import business from './pics/business.svg';
import insta from './pics/insta.svg';
import podcast from './pics/podcast.svg';
import content from './pics/content.svg';
import slogan from './pics/slogan.svg';
import essay from './pics/essay.svg';
import chatbot from './pics/chatbot.svg';
import tone from './pics/tone.svg';
import article from './pics/article.svg';
import story from './pics/story.svg';
import explain from './pics/explain.svg';
import sentence from './pics/sentence.svg';
import paragraph from './pics/paragraph.svg';
import gram from './pics/gram.svg';
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
const ToolCard = ({ id, title, description, path, icon }) => (
  <div className="pro">
    <Link to={path} id={id}>
      <div className="des">
        <p className="tooln"><strong>{title} </strong></p>
        <h5>AI Tools</h5>
        <h4>{description}</h4>
      </div>
      <img src={icon} alt="Tool Icon" style={{ width: "24px", height: "24px", fill:"#06685f", transition: "all 0.6s cubic-bezier(0.23, 1, 0.320, 1)" }} />
    </Link>
  </div>
);
export const cards = [
  { id: "imageGenerator", title: "AI Image Generator", description: "Make your thoughts to image", path: "/AiTools/ImageGenerator", icon: image },
  { id: "BlogOutline", title: "Blog Outline Writer", description: "Write Blog Outlines", path: "/AiTools/BlogOutline", icon: text_svg },
  { id: "codeGenerator", title: "Code Generator", description: "Generate your Codes", path: "/AiTools/CodeGenerator", icon: chatbot },
  { id: "Rephraser", title: "Text Rephraser", description: "Rephrase your text", path: "/AiTools/Rephraser", icon: text_svg },
  { id: "BusinessSlogan", title: "Business Slogan Writer", description: "Generate your Business Slogan", path: "/AiTools/BusinessSlogan", icon: slogan },
  { id: "Businessname", title: "Business Name Writer", description: "Generate Names for your Business", path: "/AiTools/Businessname", icon: slogan },
  { id: "InstagramStoryIdeas", title: "Instagram Story Ideas", description: "Generate Instagram Story Ideas", path: "/AiTools/InstagramStoryIdeas", icon: insta },
  { id: "PodcastScript", title: "Podcast Script Writer", description: "Write your Podcast Scripts", path: "/AiTools/PodcastScript", icon: podcast },
  { id: "YoutubeScript", title: "Youtube Script Writer", description: "Write Youtube Scripts", path: "/AiTools/YoutubeScript", icon: youtube },
  { id: "InstagramCaption", title: "Instagram Caption Writer", description: "Write Instagram Captions", path: "/AiTools/InstagramCaption", icon: insta },
  { id: "ToneOfVoiceChanger", title: "Tone Of Voice Changer in text", description: "Change the tone of voice of your text", path: "/AiTools/ToneOfVoiceChanger", icon: tone },
  { id: "SentenceCompleter", title: "Sentence Completer", description: "Complete your half Sentences", path: "/AiTools/SentenceCompleter", icon: sentence },
  { id: "Explainer", title: "Explainer", description: "Its gonna Explain your text", path: "/AiTools/Explainer", icon: explain },
  { id: "StoryWriter", title: "Story Writer", description: "Write your Stories on specific topics", path: "/AiTools/StoryWriter", icon: story },
  { id: "GrammerFixer", title: "Grammer Fixer", description: "Fix Grammer in your text", path: "/AiTools/GrammerFixer", icon: gram },
  { id: "SentenceRewriter", title: "Sentence Rewriter", description: "Rewrite your Sentences", path: "/AiTools/SentenceRewriter", icon: sentence },
  { id: "ContentSummarizer", title: "Content Summarizer", description: "Summarize your Content", path: "/AiTools/ContentSummarizer", icon: content },
  { id: "ArticleWriter", title: "Article Writer", description: "Write Articles", path: "/AiTools/ArticleWriter", icon: article },
  { id: "EssayWriter", title: "Essay Writer", description: "Writer Essays", path: "/AiTools/EssayWriter", icon: essay },
  { id: "codeCorrector", title: "Code Debugger", description: "Debug your Codes", path: "/AiTools/CodeDebugger", icon: chatbot },
  { id: "ContentImprover", title: "Content Improver", description: "Improve your Content", path: "/AiTools/ContentImprover", icon: content },
  { id: "ParagraphWriter", title: "Paragraph Writer", description: "Write any type of Paragraph", path: "/AiTools/ParagraphWriter", icon: paragraph },
  { id: "BlogpostIdeas", title: "Blog Post Ideas generator", description: "Generate blog post ideas", path: "/AiTools/BlogpostIdeas", icon: business },
  { id: "Translater", title: "Language Translater", description: "Translate any natural language to other", path: "/AiTools/Translater", icon: content },
  { id: "TitleWriter", title: "Title Writer", description: "Write titles for your content", path: "/AiTools/TitleWriter", icon: article },
  { id: "TitleRewriter", title: "Title Rewriter", description: "Rewrite your Title", path: "/AiTools/TitleRewriter", icon: sentence },
  { id: "ContentShortner", title: "Content Shortner", description: "Make your Content Shorter", path: "/AiTools/ContentShortner", icon: sentence },
  { id: "LinkedInPost", title: "LinkedIn Post Writer", description: "Write your LinkedIn posts", path: "/AiTools/LinkedInPost", icon: content },
];
const AiTools = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const { searchTerm, setSearchTerm } = useSearch();
  useEffect(() => {
  return () => {
    setSearchTerm("");
  };
}, [setSearchTerm]);
  const sortedCards = [...cards].sort((a, b) => b.title.localeCompare(a.title));
  const displayCards = searchTerm
    ? sortedCards.filter((card) =>
        card.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : sortedCards;
  return (
    <>
      <Helmet>
  <meta charSet="utf-8" />
  <title>AI Tools - Enhance Your Work with Powerful AI Tools</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools" />
  <meta
    name="description"
    content="Explore a variety of AI tools to enhance your work, including writing, translating, summarizing, and more. Boost your productivity with AI-powered solutions."
  />
  <meta
    name="keywords"
    content="AI tools, artificial intelligence tools, AI writing tools, AI text generation, AI text improvement, productivity tools, AI-powered tools, AI summarization, AI translation tools, AI grammar checkers, content generation, automated writing, AI text editing, natural language processing, machine learning tools, AI creativity tools, virtual writing assistant, AI content optimization, intelligent writing assistants, text analysis tools, AI chatbots, AI research tools, automated content creation, AI-based productivity, smart writing tools, AI for business, AI for education, AI analytics tools, AI-powered brainstorming, AI project management tools, AI in marketing, AI copywriting tools, content improvement tools, AI-powered text suggestions, intelligent content creation, text rewriting tools, AI for freelancers, AI tools for authors, AI writing prompts, AI-driven research assistants, machine learning in writing, AI note-taking tools, AI-enhanced learning tools, AI proofreading tools, AI for creative writing, text comparison tools, AI language tools, AI writing feedback, AI content personalization, automated proofreading, AI for presentations, speech generation tools, AI-driven content curation, AI productivity hacks, AI text summarizers, AI language translation, AI-assisted content strategy, AI insights tools, AI for content marketing, AI-enabled documentation tools, virtual collaboration tools, AI-driven brainstorming sessions, AI tools for students, AI-driven report generation, automated research assistance, AI writing analysis, AI tools for communication, online AI writing tools, AI-enhanced editing software, machine learning writing assistance."
  />
</Helmet>

      <div className="main1">
        <div className="pdf-body">
          <div className="root">
            <div className="top">
              <div className="h title_lg">AI Tools</div>
            </div>
                <div class="home_content">
                  <p class="descr gray">Easy-to-use online AI tools</p>
                  <form
                    action="#"
                    class="search_group search_group--search"
                    id="searchToolsForm"
                  >
                    <input type="text" name="q" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search" />
                    <img
                      class="search"
                      src={search}
                    />
                  </form>
              </div>
            <div className="pro-container">
              {displayCards.map((card, index) => (
                <ToolCard
                  key={index}
                  id={card.id}
                  title={card.title}
                  description={card.description}
                  path={card.path}
                  icon={card.icon}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AiTools;
