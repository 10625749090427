import React, { useRef, useState, useEffect } from "react";
import Loader from "./Loader2";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";

function ImageFormatConverter({ acceptedFormats, convertToFormat }) {
  const imageInputRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleConvertClick = async (index) => {
    const file = fileList[index];

    if (!convertToFormat) {
      console.error(`Please specify a conversion format in the props.`);
      return;
    }

    try {
      setLoading(true);
      const dataURL = await convertImageToDataURL(file);
      setFileList((prevList) => {
        const newList = [...prevList];
        newList[index].convertedURL = dataURL;
        return newList;
      });
    } catch (error) {
      console.error("Error during conversion:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const convertImageToDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL(`image/${convertToFormat}`);
          resolve(dataURL);
        };
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file.file);
    });
  };
  const handleDeleteClick = (index) => {
    setFileList((prevList) => {
      const newList = [...prevList];
      newList.splice(index, 1);
      return newList;
    });
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files || []);
    const newFileList = newFiles.map((file) => ({
      file,
      convertedURL: null,
    }));

    setFileList((prevList) => [...prevList, ...newFileList]);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {` ${acceptedFormats[0]
            .split("/")[1]
            .toUpperCase()} to ${convertToFormat.toUpperCase()} Converter`}
        </title>
        <link
          rel="canonical"
          href="http://toolsbag.io/ImageTools/imageConverter"
        />
        <meta
          name="description"
          content="Convert images between various formats like JPG, PNG, GIF, and more with our easy-to-use online tool. Fast and reliable for all your image conversion needs."
        />
        <meta
          name="keywords"
          content="
image format converter, convert jpg to png, png to jpg, gif to png, webp to jpg, jpeg to png, tiff to jpg, bmp to png, convert image online, convert gif to jpg, convert svg to png, heic to jpg, online image conversion, photo format converter, bmp to jpg, image to pdf, jpg to webp, png to webp, online image converter, convert raw to jpg, dng to jpg, nef to jpg, online file converter, jpg to gif, gif to webp, png to bmp, convert photo formats, jpg to tiff, svg to jpg, webp to png, convert tiff to png, heif to jpg, online gif to jpg converter, online image tools, convert png to gif, free image converter, format change image, convert cr2 to jpg, convert gif to bmp, image optimization tools, image compressor, jpeg to gif, bulk image conversion, convert raw formats, bmp to gif, gif to bmp, jpg to heic, convert png to ico, ico to png, png to jpg high quality, high resolution image converter, convert psd to png, convert cdr to png, eps to png, convert jpg to svg, svg to png, photo converter, batch image converter, convert jpg to ico, jpg to pdf converter, png to pdf converter, convert psd to jpg, gif to svg, tif to jpg, png to tiff, convert gif to pdf, convert heif to jpg, convert nef to jpg, raw image converter, convert png to bmp, convert bmp to ico, ico to jpg, eps to jpg, online converter for images, convert jpg to psd, convert gif to webp, high quality image conversion, convert png to psd, convert raw to jpeg, image conversion utility, convert image formats, convert to jpg, convert to png, image file converter, convert online for free, file converter, image format conversion"
        />
      </Helmet>
      <div className="container">
        <div className="text-center my-3">
          <h1 className="title_lg">{` ${acceptedFormats[0]
            .split("/")[1]
            .toUpperCase()} to ${convertToFormat.toUpperCase()} Converter`}</h1>
          <p className="mt_5">{`Convert your ${acceptedFormats[0]
            .split("/")[1]
            .toUpperCase()} files to ${convertToFormat.toUpperCase()}`}</p>
        </div>
        <div className="uploader-container uploader-container--images container">
          {fileList.length === 0 ? (
            <form className="file-upload-form">
              <label htmlFor="fileInput" className="file-upload-label">
                <div className="file-upload-design">
                  <svg viewBox="0 0 640 512" height="1em">
                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                  </svg>
                  <p>Drag and Drop</p>
                  <p>or</p>
                  <span className="browse-button">Browse file</span>
                </div>
                <input
                  type="file"
                  id="fileInput"
                  accept={acceptedFormats.join(",")}
                  ref={imageInputRef}
                  multiple
                  className="form-label"
                  onChange={handleFileChange}
                />
              </label>
            </form>
          ) : (
            <div className="container my-5 text-center">
              {fileList.map((file, index) => (
                <div key={index} className="file-upload-container">
                  <div className="image-container">
                    <div className="image-details">
                      <strong>{file.file.name}</strong>
                    </div>
                    <button
                      className="normal-button mx-2"
                      onClick={() => handleConvertClick(index)}
                    >
                      Convert
                    </button>
                    {file.convertedURL && (
                      <a
                        href={file.convertedURL}
                        download={`converted_${index}.${convertToFormat}`}
                        style={{ textDecoration: "none" }}
                      >
                        <button class="DownBtn">
                          <svg
                            class="svgIcon1"
                            viewBox="0 0 384 512"
                            height="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                          </svg>
                          <span class="icon2"></span>
                          <span class="tooltip1">Download</span>
                        </button>
                      </a>
                    )}

                    <div style={{ margin: "0px 10px 0px" }}>
                      <button
                        className="deleteButton"
                        onClick={() => handleDeleteClick(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 50 59"
                          className="bin"
                        >
                          <path
                            fill="#B5BAC1"
                            d="M0 7.5C0 5.01472 2.01472 3 4.5 3H45.5C47.9853 3 50 5.01472 50 7.5V7.5C50 8.32843 49.3284 9 48.5 9H1.5C0.671571 9 0 8.32843 0 7.5V7.5Z"
                          ></path>
                          <path
                            fill="#B5BAC1"
                            d="M17 3C17 1.34315 18.3431 0 20 0H29.3125C30.9694 0 32.3125 1.34315 32.3125 3V7.5H17V3Z"
                          ></path>
                          <path
                            fill="#B5BAC1"
                            d="M15.5003 49.5402C14.1196 49.5402 13.0003 48.4209 13.0003 47.0402V24.9602C13.0003 23.5795 14.1196 22.4602 15.5003 22.4602C16.881 22.4602 18.0003 23.5795 18.0003 24.9602V47.0402C18.0003 48.4209 16.881 49.5402 15.5003 49.5402ZM29.5003 47.0402C29.5003 48.4209 30.6196 49.5402 32.0003 49.5402C33.381 49.5402 34.5003 48.4209 34.5003 47.0402V24.9602C34.5003 23.5795 33.381 22.4602 32.0003 22.4602C30.6196 22.4602 29.5003 23.5795 29.5003 24.9602V47.0402Z"
                            clipRule="evenodd"
                            fillRule="evenodd"
                          ></path>
                          <path
                            fill="#B5BAC1"
                            d="M2 13H48L47.6742 21.28H2.32031L2 13Z"
                          ></path>
                        </svg>

                        <span className="tooltip">Reset</span>
                      </button>
                    </div>
                  </div>
                  {index < fileList.length - 1 && (
                    <hr className="line-between-images" />
                  )}
                </div>
              ))}
              {loading && <Loader text="Converting" />}
            </div>
          )}
        </div>
      </div>
      <Instructions
          description=" This tool allows you to convert your images to different formats such
          as PNG, JPG, or WebP. Simply upload your image below, choose your
          preferred format, and after conversion, download the image in the
          selected format."
          title=" Convert Image Formats"
          step1="Upload the image you want to convert to another format."
          step2=" Click Convert to process the image."
          step3= "Download it in that selected format."
        />
    </>
  );
}

export default ImageFormatConverter;
