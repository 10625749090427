import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { cards as pdfCardsData } from "./components/PdfTools";
import { cards as aiCardsData } from "./components/AiTools";
import { cards as imageCardsData } from "./components/ImageTools";

const createSubNav = (cardsData) =>
  cardsData.map((card) => ({
    title: card.title,
    path: card.path,
    icon:<img
	src={card.icon}
	style={{
	  width: "20px",
	  height: "24px",
	  fill: "#06685f",
	  transition: "all 0.6s cubic-bezier(0.23, 1, 0.320, 1)",
	}}
	alt="SVG Icon"
	className="icon mx-1"
  />
  }));

export const SidebarData = [
  {
    title: "Home",
    path: '/',
    icon: <IoIcons.IoMdHelpCircle style={{ fill: 'black' }} />
  },
  {
    title: "Image Tools",
	path: '/ImageTools',
    icon: <IoIcons.IoIosPaper style={{ fill: 'black' }} />,
    iconClosed: <RiIcons.RiArrowDownSFill style={{ fill: 'black' }} />,
    iconOpened: <RiIcons.RiArrowUpSFill style={{ fill: 'black' }} />,
    subNav: createSubNav(imageCardsData),
  },
  {
	  title: "PDF Tools",
	  path: '/PdfTools',
	  icon: <FaIcons.FaEnvelopeOpenText style={{ fill: 'black' }} />,
	  iconClosed: <RiIcons.RiArrowDownSFill style={{ fill: 'black' }} />,
	  iconOpened: <RiIcons.RiArrowUpSFill style={{ fill: 'black' }} />,
	  subNav: createSubNav(pdfCardsData),
	},
	{
		title: "AI Tools",
		path: '/AiTools',
  icon: <AiIcons.AiFillHome style={{ fill: 'black' }} />,
    iconClosed: <RiIcons.RiArrowDownSFill style={{ fill: 'black' }} />,
    iconOpened: <RiIcons.RiArrowUpSFill style={{ fill: 'black' }} />,
    subNav: createSubNav(aiCardsData),
},
{
  title: "About Us",
  path: '/about',
  icon: <AiIcons.AiFillHome style={{ fill: 'black' }} />,
  subNav: [
  ],
},
{
  title: "Contact",
  path:'/contact-us',
  icon: <FaIcons.FaPhone style={{ fill: 'black' }} />,
},
{
	title: "Privacy Policy",
  path:'/PrivacyPolicy',
    icon: <IoIcons.IoMdHelpCircle style={{ fill: 'black' }} />,
},
];
