import React, { useState, useEffect, useRef } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";
const educationLevels = ["Formal", "Informal", "Assertive","Optimistic","Humorous","Pessimistic","Friendly","Motivating","Serious","Amused","Curious","Sadness","Absurd","Accusatory","Acerbic","Aggression","Apathetic","Enthusiastic","Information","Arrogant","Belligerent",];
const ContentImprover = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const outputRef = useRef(null);
  const [paragraphs, setParagraphs] = useState(2);
  const [educationLevel, setEducationLevel] = useState("Formal");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/1000`;
  };
  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Improve this content: "${input}" according to the tone like "${educationLevel}"`;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
      // setInput("");
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };
  const handleCopy = () => {
    const textToCopy = messages[currentIndex]?.content || "";
    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    } else {
      alert("No text to copy");
    }
  };
  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2); // Reset paragraphs to default value
    setEducationLevel("Formal"); // Reset education level to default value
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleEducationLevelChange = (event) => {
    setEducationLevel(event.target.value);
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <>
<Helmet>
  <meta charSet="utf-8" />
  <title>Content Improver - Improve Your Content Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/ContentImprover" />
  <meta
    name="description"
    content="Improve your content online. Perfect for enhancing the quality, style, and clarity of your writing."
  />
  <meta
    name="keywords"
    content="content improver, improve content, enhance writing, content enhancement, writing improvement, quality writing, writing style, content clarity, editing tool, online writing, writing assistant, text improvement, content optimization, writing enhancement, text enhancement, writing tool, professional writing, writing services, content quality, content refinement, clarity in writing, writing tips, content generator, paragraph improvement, rewrite content, rewriting tool, grammar checker, proofreading tool, readability improvement, online editing, style guide, content revision, effective writing, sentence structure, word choice, tone adjustment, language enhancement, express ideas better, articulate thoughts, persuasive writing, writing clarity, writing coherence, content flow, concise writing, eliminate jargon, simplify text, enhance paragraphs, rewrite sentences, online rewriting, content rewriting, rephrase tool, sentence fluency, grammar correction, fix grammar issues, improve readability, content polish, creative writing aid, writing skills, sentence variety, avoid repetition, content quality check, articulate writing, effective communication, content proofing, content analysis, enhance engagement, impactful writing, writing creativity,
     sentence improvement, transition phrases, content transformation, content clarity check, write better, smart writing tool, academic writing aid, content efficiency, rewrite paragraphs, fresh content, original content creation, structured writing, style improvement, enhance vocabulary, concise paragraphs, rewording tool, focus on audience, improve articulation, effective editing, online content improvement, online text enhancer, refine writing style, content style guide, enhance creativity, content flow improvement, language improvement, academic content tool, practical writing tool, effective text editing, writing techniques, content evaluation, seamless writing, enhanced content presentation, content writing tips, comprehensive editing, efficient writing process, engaging writing, impactful communication, academic paper improvement, detailed editing, clear communication, enhance writing skills, online text improvement, dynamic writing tool, content adjustment, voice and tone, writing consistency, sharpen writing, reader engagement, online content optimization, textual analysis, writing quality assurance,
      polished content, effective messaging, adaptable writing tool, textual coherence, fluency improvement, writing refinement, comprehensive content improvement, context-aware writing, academic writing support, content assessment, professional editing tool, effective sentence structure, writing clarity tools, expressive writing, enhanced writing clarity, academic text improvement, concise communication, robust editing tool, enhance textual meaning, precise writing, creative content generation, coherent writing, cohesive paragraphs, persuasive content, audience-focused writing, text evaluation, seamless content, writing feedback, interactive writing tool, content structure improvement, articulate communication, comprehensive content assessment, readability checker, sentence completion tool, engagement strategies, thorough editing, focused writing, content optimization strategies, contextual writing aid, tailored writing solutions, content creation tool, writing analysis tool, professional content improvement, clarity-driven writing, cohesive writing, effective content strategy, quality assurance for writing, articulate your ideas, sentence cohesion, text coherence improvement, online writing improvement, writing effectiveness, powerful communication, text simplification, impactful storytelling, writing productivity, enhance text quality, creative writing tool, tailored content, writing enhancement platform, enhance message delivery, dynamic editing, content coherence, seamless communication, smart editing solutions, boost writing quality, academic writing tool, refine text clarity, clear and concise writing, engaging content creation, quality content assessment, innovative writing tools, expressive text, skillful writing, effective paragraph structuring, logical writing flow, enhance idea expression, thoughtful editing, writing improvement strategies, articulate your message"
  />
</Helmet>

    <div className="text-center">
        <h1 className="title_lg">Content Improver</h1>
        <p className="mt_5">Improve your content</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block",}}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Content to improve</h4>
              <p>What's your content you want to improve?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/1000
                  </span>
                </div>
                <textarea
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="Enter your content here"
                  rows="9"
                  cols="100"
                  maxLength="2000"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="education_level">Tone of voice</label>
                <select
                  className="form-control my-2"
                  value={educationLevel}
                  onChange={handleEducationLevelChange}
                >
                  {educationLevels.map((level, index) => (
                    <option key={index} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
        <Instructions
  description="This tool helps you enhance your existing content by suggesting improvements. Paste your text, and the tool will provide recommendations."
  title="Improve Your Content"
  step1="Paste the content you want to improve in the input field."
  step2="The tool will analyze your text and suggest enhancements."
  step3="Review the suggestions and apply the changes to enhance your writing."
/>
      </div>
    </>
  );
};

export default ContentImprover;
