import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";
const StoryWriter = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const textAreaRef = useRef(null);
  const [paragraphs, setParagraphs] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const outputRef = useRef(null);
 const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/60`;
  };

  const clickHandle = async () => {
    if (!input || !paragraphs ) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    const wordCount = input.trim().split(/\s+/).length;
    if (wordCount < 5) {
      alert("Please enter at least 5 words for the topic.");
      return;
    }
    try {
      setIsLoading(true);
      const prompt = `Write an amazing story on: "${input}" of "${paragraphs}" no of paragraphs `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; 
      const sentencesPerParagraph = 10; 
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };

      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2); 
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handleParagraphsChange = (event) => {
    const newValue = parseInt(event.target.value);
    setParagraphs(isNaN(newValue) ? 0 : newValue);
  };

  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>Story Writer - Write Stories on Specific Topics Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/StoryWriter" />
  <meta
    name="description"
    content="Write stories on specific topics online. Perfect for creative writing, storytelling, and content generation."
  />
  <meta
    name="keywords"
    content="story writer, write stories online, creative story writer, story generator, online story creation, narrative writer, fiction writing tool, storytelling tool, creative writing assistant, generate stories, prompt-based storytelling, story development, story ideas, plot generator, write short stories, online creative writing, personalized story writer, interactive storytelling, fiction creator, character development, story outline tool, write narrative, creative prompts, enhance storytelling, online narrative generator, engaging stories, story structure, collaborative story writing, theme-based story generator, imaginative writing, online narrative tool, story composition, writing help, story crafting, unique story creation, story brainstorm, digital storytelling, plot development."
  />
</Helmet>


    <div className="text-center">
        <h1 className="title_lg">Story writer</h1>
        <p className="mt_5">Write Story on any type of scenerio</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Essay Topic</h4>
              <p>What's your essay about?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/60
                  </span>
                </div>
                <textarea
                 ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="i.e., thirsty crow"
                  rows="10"
                  cols="100"
                  maxLength="60"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="number_answers">Number of Paragraphs</label>
                <input
                  type="number"
                  className="form-control my-2"
                  value={paragraphs}
                  onChange={handleParagraphsChange}
                  placeholder="Number of paragraphs"
                />
              </div>
              
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
        <Instructions
  description="This tool helps you create compelling stories. Enter your story idea or prompt, and the tool will generate a structured narrative."
  title="Create Your Story"
  step1="Enter your story idea or prompt in the input field."
  step2="The tool will generate a detailed outline and narrative based on your input."
  step3="Review the generated story and modify it as needed."
/>

      </div>
    </>
  );
};

export default StoryWriter;
