import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";
const TitleWriter = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const [input, setInput] = useState("");
  const [paragraphs, setParagraphs] = useState(6);
  const outputRef = useRef(null);
  const [educationLevel, setEducationLevel] = useState("Formal");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/1000`;
  };

  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Write an amazing title about this content : "${input}" `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2); 
    setEducationLevel("Formal"); 
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>
    Title Writer - Write Titles for Your Content Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/AiTools/TitleWriter"
  />
  <meta
    name="description"
    content="Write titles for your content online. Perfect for generating catchy and compelling titles for articles, blog posts, and more."
  />
  <meta
    name="keywords"
    content="title writer, write titles, title generator, create content titles, catchy titles, blog title ideas, article title generator, title creator, compelling titles, content title ideas, effective titles, online title generator, title suggestions, creative title writing, SEO-friendly titles, headline generator, title brainstorming, unique title ideas, title optimization, generate titles online, attractive titles, eye-catching titles, content title creator, title design, professional titles, descriptive titles, catchy headlines, headline writing tool, article headline ideas, blog post titles, writing compelling titles, marketing titles, business title ideas, title inspiration, quick title generator, interactive title generator, topic titles, title formulation, persuasive titles, social media titles, title enhancement, unique blog titles, blog titles that attract readers, title alternatives, engaging titles, title selection, title research, content marketing titles, generate effective titles, title structures, headline analytics, title writing workshop, unique content titles, attention-grabbing headlines, focused title writing, actionable titles, title customization, headline analytics tool, brainstorm title phrases, innovative title suggestions, title modification, optimize for clicks, simple title generator, title analysis, content targeting, thematic titles, title enhancement techniques, creative writing titles, title strategies, user-friendly title generator, quality title writing, fast title generation, title variation strategies, engaging headlines, headline writing workshop, title alternatives analysis, create unique titles, brainstorming catchy titles, marketing-focused titles, strategic title writing, online title creation tool, content title generator, responsive title writing, title writing trends, title improvement strategies, trending article titles, title writing assistance"
  />
</Helmet>


      <div className="text-center">
        <h1 className="title_lg">Title writer</h1>
        <p className="mt_5">Write Title for your content</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Title</h4>
              <p>What's your content for which you want title?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/1000
                  </span>
                </div>
                <textarea
                  ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="Enter your content here"
                  rows="14"
                  cols="100"
                  maxLength="1000"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
        <Instructions
          description="  This tool helps you generate compelling and attention-grabbing titles for your articles, blog posts, or content pieces. Whether you're struggling to come up with a catchy title or need inspiration, simply input a brief description of your content, and the tool will generate engaging title suggestions."
          title="Generate a Title for Your Content"
          step1="Enter a brief description or summary of your content in the provided input field."
          step2="Click the Generate Title button to receive a list of potential title suggestions."
          step3="Review the generated titles and select the one that best fits your content or modify it for a perfect fit. Use the selected title for your article, blog post, or any content, and repeat the process if you're looking for more options!"
        />
      </div>
    </>
  );
};

export default TitleWriter;
