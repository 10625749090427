import React, { useState, useEffect, useRef } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";
const Translater = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [paragraphs, setParagraphs] = useState("");
  const outputRef = useRef(null);
  const textAreaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/1000`;
  };
  const clickHandle = async () => {
    if (!input || !paragraphs) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Translate this content: "${input}" in "${paragraphs}" language`;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20;
      const sentencesPerParagraph = 10;
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handleParagraphsChange = (event) => {
    setParagraphs(event.target.value);
  };

  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>
    Language Translator - Translate Any Natural Language Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/AiTools/Translater"
  />
  <meta
    name="description"
    content="Translate any natural language online. Perfect for translating text into multiple languages quickly and easily."
  />
  <meta
    name="keywords"
    content="language translator, translate text, text translation tool, online language translation, natural language translation, translate documents, multilingual translation, instant translation, text converter, translate languages, language translation tool, translate online, language converter, translation services, translate speech, accurate translation, text interpreter, translation software, translate phrases, language translation app, online translator, real-time translation, translation solutions, professional translation, language services, translate paragraphs, translate essays, free translation tool, document translation, translation accuracy, foreign language translation, translate conversations, quick translation, language translator app, multilingual communication, translate articles, user-friendly translator, translation API, automatic translation, translate words, text localization, effective translation, online language services, translate sentences, translate websites, cross-language communication, idiomatic translation, cultural translation, translate for travel, instant language translation, translate in context, interpretation services, translate jargon, specialized translation, community translation, translate websites for SEO, language learning aid, translate with ease, translator for social media, academic translation, translate for business, customer support translation, professional document translation, translate medical texts, user-generated translations, voice translation tool,
     visual translation, translate audio to text, idioms translation, community-driven translation, online dictionary, contextual translation, language pair translation, translate manually, interactive translation, peer-to-peer translation, customizable translation settings, user-friendly interface, secure translation, translate in real-time, assistive translation technology, collaborative translation, industry-specific translation, mobile translation app, desktop translation software, translation for e-commerce, global communication, translate emails, translate scripts, automatic language detection, translate for academic purposes, translation for marketing, instant feedback translation, secure document translation, enhance communication across languages, effective language conversion, non-English language translation, expert translation service, seamless translation experience, tech-enabled translation, translation management system, translate instructions, remote translation services, translation quality assurance, translate for content creators, enhance language understanding, versatile translation tool, corporate translation services, personalized translation solutions, community language support, translate slang, human-like translation, AI-powered translation, user-reviewed translations, localization for businesses, professional proofing services, translate for technical writing, instant language switch, translate social media posts, translation for hospitality, improve cross-border communication"
  />
</Helmet>


      <div className="text-center">
        <h1 className="title_lg">Language Translater</h1>
        <p className="mt_5">Translate your natural Language</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Translate</h4>
              <p>What's your content to Translate ?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/1000
                  </span>
                </div>
                <textarea
                ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="Enter your data here"
                  rows="10"
                  cols="100"
                  maxLength="1000"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="number_answers">Language:</label>
                <input
                  type="text"
                  className="form-control my-2"
                  value={paragraphs}
                  onChange={handleParagraphsChange}
                  placeholder="e.g. Urdu, Turkish"
                />
              </div>

              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
        <Instructions
          description="This tool helps you translate text between different languages with ease. Whether you need to translate a document, a message, or any piece of content, simply enter your text, select the desired language, and the tool will provide an accurate translation."
          title="Translate Your Text"
          step1="Enter the text you want to translate into the provided input field."
          step2="Select the target language from the available options."
          step3=" Click the Translate button to receive the translated text."
        />
      </div>
    </>
  );
};

export default Translater;
