import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SideBarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import { useAuth0 } from "@auth0/auth0-react";
import ReCAPTCHA from "react-google-recaptcha";

const Nav = styled.div`
  background: black;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0 1rem;
`;

const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #f7f7f7;
  width: 25vw;
  max-width: 335px;
  height: 100vh;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: ${({ $sidebar }) => ($sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  overflow-y: auto;
  border-right: 1px solid black;

  @media (max-width: 768px) {
    width: 50vw;
  }

  @media (max-width: 480px) {
    width: 75vw;
  }
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Overlay = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  justify-content: center;
  align-items: center;
  z-index: 9999; /* On top of everything */
`;
const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const sidebarRef = useRef(null);
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const showSidebar = () => {
    setSidebar((prevState) => !prevState);
  };
  const handleCaptchaChange = (value) => {
    if (value) {
      loginWithRedirect();
      setShowCaptcha(false);
    }
  };
  const handleLoginClick = () => {
    setShowCaptcha(true);
  };
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebar(false);
    }
  };
  useEffect(() => {
    if (sidebar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebar]);
  return (
    <>
      <IconContext.Provider value={{ color: "white" }}>
        <Nav style={{ backgroundColor: "#008080" }}>
          <NavIcon to="#">
            {sidebar ? (
              <AiIcons.AiOutlineClose
                style={{ color: "black" }}
                onClick={showSidebar}
              />
            ) : (
              <FaIcons.FaBars
                style={{ color: "black" }}
                onClick={showSidebar}
              />
            )}
            {isAuthenticated && (
              <div>
                <img
                  src={user.picture}
                  style={{ marginLeft: "20px" }}
                  className="image1 mt-1"
                />
              </div>
            )}
          </NavIcon>
          <Link to="/">
            <h1 className="heading">&nbsp;&nbsp;&nbsp;&nbsp;Tools Bag</h1>
          </Link>
          {isAuthenticated ? (
            <button
              className="Btn1"
              onClick={() =>
                logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }
            >
              <div className="sign">
                <svg viewBox="0 0 512 512">
                  <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                </svg>
              </div>
              <div className="text0">Logout</div>
            </button>
          ) : (
            <>
              <button
                style={{ backgroundColor: "black" }}
                className="Btn1"
                onClick={handleLoginClick}
              >
                <div className="sign">
                  <svg viewBox="0 0 512 512">
                    <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                  </svg>
                </div>
                Log In
              </button>
              <Overlay show={showCaptcha}>
                <ReCAPTCHA
                  sitekey="6LddzG0qAAAAADpvelONORyXQGizHM2Yy1Lm0PNu"
                  onChange={handleCaptchaChange}
                />
              </Overlay>
            </>
          )}
        </Nav>
        <SidebarNav ref={sidebarRef} $sidebar={sidebar}>
          <SidebarWrap>
            {SidebarData.map((item, index) => (
              <SubMenu style={{ height: "20px" }} item={item} key={index} />
            ))}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
