import React from 'react';

const Instructions = ({ description, title, step1, step2, step3 }) => {
  return (
    <div className="container my-5">
      <p className="mt-5">
         {description}
        </p>
        <div className="row align-items-center small_gutter">
          <div className="col-lg-5 mt_30">
            <h3 className="sub_title">STEP BY STEP</h3>
            <h2 className="title">How To {title}</h2>
            <p className="mt_25">Follow along with the steps below</p>
            <div className="step_item_box pt_10">
              <div className="step_item mt_30">
                <h4>Step 1</h4>
                <p className="mt_5">
                  {step1}
                </p>
              </div>
              <div className="step_item mt_30">
                <h4>Step 2</h4>
                <p className="mt_5"> {step2}</p>
              </div>
              <div className="step_item mt_30">
                <h4>Step 3</h4>
                <p className="mt_5">
                {step3}
                </p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Instructions;
