import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/favicon.webp'
const Footer = () => {
  return (
    <footer>
    <div className="container">
      <div className="row small_gutter container">
        <div className="col-lg-4 mt_30">
          <Link to="https://toolsbag.io/">
            <img
              src={logo}
              className="img-fluid logo-dark my-3"
              alt=""
              width="30"
              height="30"
            />
          </Link>
          <p className="mt_15">
          Empower your digital journey with our comprehensive suite of tools at ToolHub - where innovation meets convenience and Unlock the potential of technology with DigitalToolbox - your go-to destination for a wide array of digital utilities tailored to enhance your online experience.
          </p>
        </div>
        <div className="col-lg-8">
          <div className="row small_gutter">
            <div className="col-lg-2 col-md-3 col-6 mt_30">
              <h4>Navigate</h4>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li>
                  <Link to="/PrivacyPolicy">Privacy Policy</Link>
                </li>
                <li><Link to="/contact-us">Contact</Link></li>
              </ul>
            </div>
            <div className="col-lg-10 col-md-9 mt_30">
              <h4>Featured Tools</h4>
              <div className="row small_gutter">
                <div className="col-lg-3 col-6">
                  <ul>
                    <li>
                      <Link to="/AiTools/EssayWriter"
                        >Essay Writer</Link>
                    </li>
                    <li>
                      <Link to="/AiTools/ContentImprover"
                        >Content Improver</Link>
                    </li>
                    <li>
                      <Link to="/AiTools/ParagraphWriter"
                        >Paragraph Writer</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-6">
                  <ul>
                    <li>
                      <Link to="/ImageTools/BackGround"
                        >Remove Background from Image</Link>
                    </li>
                    <li>
                      <Link to="/PdfTools/PdfMerger">Merge PDF</Link>
                    </li>
                    <li>
                      <Link to="/ImageTools/pdftoimage">PDF to JPG</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-6">
                  <ul>
                    <li>
                      <Link to="/PdfTools/Image-to-PDF"
                        >JPG to PDF</Link
                      >
                    </li>
                    <li>
                      <Link to="/ImageTools/RoundImageCropper"
                        >Round Profile/Image Maker</Link
                      >
                    </li>
                    <li>
                      <Link to="/PdfTools/PdfSplitter">Split PDF</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-6">
                  <ul>
                    <li>
                      <Link to="/ImageTools/imageUpscaler"
                        >Upscale Image</Link
                      >
                    </li>
                    <li>
                      <Link to="/PdfTools/PageNumberWriter"
                        >Page Numbers Writer</Link>
                    </li>
                    <li>
                      <Link to="/PdfTools/WordToPdfConverter"
                        >Word to PDF</Link >
                    </li>
                    <li>
                      <Link to="/" className="active">All tools</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer_bottom">
      <div className="container">
        <div className="row small_gutter align-items-center ">
          <div className="col-md-7 mt_10" >
            <p>
              <img
                src={logo}
                alt="Box20"
                loading="lazy"
                width="30"
                height="30"
              />
              Tools Bag is a Free to use site.
            </p>
          </div>
          <div className="col-md-5 text-md-right mt_10">
            <p className="copy_right">@ 2024 Tools Bag. All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  );
};

export default Footer;
