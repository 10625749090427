import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";
const GrammerFixer = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const [input, setInput] = useState("");
  const [paragraphs, setParagraphs] = useState(6);
  const [educationLevel, setEducationLevel] = useState("Formal");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const outputRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };
  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    const wordCount = input.trim().split(/\s+/).length;
    if (wordCount < 5) {
      alert("Please enter at least 5 words for the topic.");
      return;
    }
    try {
      setIsLoading(true);
      const prompt = `Fix the grammer in these lines: "${input}" `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });

      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };

      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2);
    setEducationLevel("Formal"); 
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };

  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
      <Helmet>
  <meta charSet="utf-8" />
  <title>Grammar Fixer - Fix Grammar in Your Text Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/GrammarFixer" />
  <meta
    name="description"
    content="Fix grammar in your text online. Perfect for improving sentence structure and correcting grammatical mistakes."
  />
  <meta
    name="keywords"
    content="grammar fixer, grammar checker, correct grammar mistakes, grammar correction tool, online grammar checker, grammar correction online, fix grammatical errors, sentence structure improvement, proofreading tool, punctuation correction, language editing, writing assistant, text editing tool, online proofreading, grammar improvement, spell checker, enhance writing quality, sentence clarity, writing enhancement, text correction, writing help, ESL grammar checker, academic writing tool, English grammar checker, correct syntax, writing optimization, correct sentence structure, improve writing style, online editing tool, grammar assistant, real-time grammar check, contextual grammar checker, text proofreading."
  />
</Helmet>

      <div className="text-center">
        <h1 className="title_lg">Grammer Fixer</h1>
        <p className="mt_5">Fix your Grammer and make your text correct</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Your text</h4>
              <p>What's your text in which you want to fix grammer?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/100
                  </span>
                </div>
                <textarea
                  ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder=""
                  rows="14"
                  cols="100"
                  maxLength="100"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
        <Instructions
  description="This tool helps you identify and correct grammar mistakes in your text. Paste your text, and the tool will suggest corrections."
  title="Fix Your Grammar"
  step1="Paste the text you want to check for grammar issues in the input field."
  step2="The tool will analyze your text and highlight any grammatical errors."
  step3="Review the suggested corrections and apply them to improve your writing."
/>
      </div>
    </>
  );
};

export default GrammerFixer;
