import React, { useState, useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Instructions from './Instructions';
const HTMLToPDFConverter = () => {
  const [htmlContent, setHtmlContent] = useState('');
  const [cssContent, setCssContent] = useState('');
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState('');
  const [pdfInstance, setPdfInstance] = useState(null);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handlePasteHtml = async () => {
    const text = await navigator.clipboard.readText();
    setHtmlContent(text);
  };
  const handlePasteCss = async () => {
    const text = await navigator.clipboard.readText();
    setCssContent(text);
  };

  const handleConvert = () => {
    const div = document.createElement('div');
    const fullContent = `
      <style>
        ${cssContent}
      </style>
      ${htmlContent}
    `;
    div.innerHTML = fullContent;
    const opt = {
      margin: 1,
      filename: 'styled_document.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        useCORS: true, 
        logging: true 
      },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    const pdf = html2pdf().from(div).set(opt);
    pdf.outputPdf('datauristring').then((pdfDataUri) => {
      setPdfPreviewUrl(pdfDataUri);
      setPdfInstance(pdf); 
    });
  };
  const handleReset = () => {
    setHtmlContent('');
    setCssContent('');
    setPdfPreviewUrl('');
    setPdfInstance(null);
  };
  const handleDownload = () => {
    if (pdfInstance) {
      pdfInstance.save();
    }
  };
  return (<>
    <Helmet>
  <meta charSet="utf-8" />
  <title>
    HTML to PDF Converter - Free Online Tool to Convert HTML to PDF
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/HtmlToPdf"
  />
  <meta
    name="description"
    content="Convert HTML to PDF online for free with our fast and easy-to-use HTML to PDF converter tool. Just input your HTML and get the PDF version instantly."
  />
  <meta
    name="keywords"
    content="html to pdf, online html to pdf converter, convert html to pdf, free html to pdf tool, pdf converter, html to pdf online, HTML to PDF conversion, convert web page to PDF, HTML document to PDF, generate PDF from HTML, HTML to PDF online free, web page to PDF converter, PDF generation from HTML, fast HTML to PDF tool, convert HTML code to PDF, online PDF converter for HTML, HTML file to PDF, PDF from HTML document, best HTML to PDF converter, free HTML to PDF service, HTML to PDF online tool, web content to PDF, quick HTML to PDF conversion, HTML to PDF formatting, free HTML PDF tool, convert HTML5 to PDF, upload HTML to convert to PDF, HTML to PDF creator, web page PDF generator, convert HTML to PDF with links, HTML to PDF with images, HTML to PDF with styles, online document conversion, free PDF maker from HTML, browser HTML to PDF converter, PDF from website, save HTML as PDF, convert HTML content to PDF, HTML to PDF solution, easy HTML to PDF, HTML to PDF app, online HTML PDF conversion tool, convert HTML table to PDF, HTML to PDF with footers, HTML to PDF with headers, HTML to PDF without losing formatting, HTML PDF tool, professional HTML to PDF conversion, HTML print to PDF, create PDF from HTML documents, cloud-based HTML to PDF service, HTML to PDF API, web development PDF generator, HTML to PDF batch converter, HTML content PDF output, fast online HTML to PDF, HTML to PDF for developers, versatile HTML to PDF tool, instant HTML PDF converter."
  />
</Helmet>
    <div className="container my-4">
      <div className="row">
        <div className="text-center">
        <h1 className="title_lg">HTML and CSS to PDF Converter</h1>
        <p className="mt_5">Convert your HTML and CSS to PDF</p>
      </div>
        <div className="col-12 mb-3">
          <textarea
            className="form-control"
            placeholder="Enter HTML content here"
            value={htmlContent}
            onChange={(e) => setHtmlContent(e.target.value)}
            style={{ height: '150px' }}
          />
          <button className="btn btn-secondary mt-2" onClick={handlePasteHtml}>
            Paste HTML
          </button>
        </div>
        <div className="col-12 mb-3">
          <textarea
            className="form-control"
            placeholder="Enter CSS styles here"
            value={cssContent}
            onChange={(e) => setCssContent(e.target.value)}
            style={{ height: '150px' }}
          />
          <button className="btn btn-secondary mt-2" onClick={handlePasteCss}>
            Paste CSS
          </button>
        </div>
        <div className="col-12 text-center">
          <button className="normal-button" onClick={handleConvert}>Convert to PDF</button>
          <button className="Reset-button mx-1" onClick={handleReset}>Reset</button>
          <button
            className="buttonDownload"
            onClick={handleDownload}
            disabled={!pdfInstance} 
          >
            Download PDF
          </button>
        </div>
      </div>
      {pdfPreviewUrl && (
        <div className="row mt-4">
          <div className="col-12">
            <iframe
              src={pdfPreviewUrl}
              className="w-100"
              style={{ height: '400px', border: '1px solid #ccc' }}
              title="PDF Preview"
            />
          </div>
        </div>
      )}
      <Instructions
          description="  This tool allows you to easily convert HTML content into PDF format. Simply enter your HTML and CSS below, and after converting, you can download the result as a PDF file. Perfect for saving, sharing, or printing HTML content as a PDF document."
          title="Convert HTML to PDF"
          step1=" Enter the HTML content and optional CSS styles in the provided fields."
          step2="Click the Convert to PDF button to generate a PDF version of your HTML content."
          step3="Once the conversion is complete, click Download PDF to save your file."
        />
    </div>
    </>
  );
};

export default HTMLToPDFConverter;
