import React, { useState, useRef } from "react";
import axios from "axios";
import Loader from "./Loader1";

const ImageGenerator = () => {
  const [prompt, setPrompt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // Reference to the image section
  const imageSectionRef = useRef(null);
  // Function to call OpenAI API and generate the image
  const generateImage = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/images/generations",
        {
          model: "dall-e-3",
          prompt: prompt,
          n: 1,
          size: "1024x1024",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`, 
          },
        }
      );
      setImageUrl(response.data.data[0].url);
      if (imageSectionRef.current) {
        imageSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (err) {
      setError(
        "Failed to generate image. Please check your Keywords and try again."
      );
      console.error(err);
    }
    setLoading(false);
  };
  const handleReset = () => {
    setPrompt("");
    setImageUrl("");
    setError("");
  };
  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "generated-image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <div className="text-center my-3">
        <h1 className="title_lg">AI Image Generator</h1>
        <p className="tools-description">Generate AI images</p>
      </div>
      <div className="input-container2 container">
        <input
          type="text"
          placeholder="e.g. Kids playing on Mars"
          value={prompt}
          maxLength="100"
          onChange={(e) => setPrompt(e.target.value)}
        />
        <button
          onClick={generateImage}
          disabled={loading}
          className="button2"
        >
          {loading ? "Generating..." : "Generate"}
        </button>
      </div>
      <div className="image-generator" ref={imageSectionRef}>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {imageUrl && (
          <>
            <div className="image-card">
              <div className="text-center">
                <p className="mt_15">Generated Image</p>
              </div>
              <div className="image-container" style={{ height: "100%" }}>
                {loading ? (
                  <Loader text="Generating..." />
                ) : (
                  <img
                    src={imageUrl}
                    alt="Generated from OpenAI"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </div>
              <div className="button-container">
                <div className="normal-button-container">
                  <button className="normal-button" onClick={generateImage}>
                    Regenerate
                  </button>
                  <button
                    className="buttonDownload"
                    onClick={downloadImage}
                  >
                    Download
                  </button>
                  <button className="Reset-button" onClick={handleReset}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default ImageGenerator;
