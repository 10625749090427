import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import Loader from "./Loader1";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomOneLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import CopyButton from "./CopyButton";

const messageStyle = {
  padding: "10px",
  borderRadius: "10px",
  marginBottom: "10px",
  alignSelf: "flex-start",
};

const CodeDebugger = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const textAreaRef = useRef(null);
  const outputRef = useRef(null);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleChange = (event) => {
    setInput(event.target.value);
    document.getElementById("character-counter").innerText = `${event.target.value.length}/2000`;
  };

  const clickHandle = async () => {
    if (!input) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Debug the following code:\n\n${input}\n\nIdentify issues and provide the corrected code.`;
      const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });

      const userMessage = { role: "user", content: prompt };
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: 1000,
      });

      const aiContent = chatCompletion.choices[0].message.content;
      const aiMessages = [
        { role: "assistant", content: aiContent, isCode: aiContent.includes("```") },
      ];

      setMessages([...messages, ...aiMessages]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "debugged_code.txt");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };

  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const copyCode = (code) => {
    navigator.clipboard.writeText(code).then(() => alert("Copied to clipboard!"));
  };

  const renderOutput = () => {
    const currentMessage = messages[currentIndex];
    if (!currentMessage) return null;

    const codePattern = /```([\s\S]*?)```/g;
    const sections = currentMessage.content.split(codePattern);

    return sections.map((section, index) => (
      <div key={index} style={{ margin: "10px 0" }}>
        {index % 2 === 1 ? (
          <div style={{ position: "relative" }}>
            <SyntaxHighlighter language="javascript" style={atomOneLight} showLineNumbers>
              {section}
            </SyntaxHighlighter>
            <button
              onClick={() => copyCode(section)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                padding: "5px",
                background: "#007bff",
                color: "white",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Copy Code
            </button>
          </div>
        ) : (
          <p>{section}</p>
        )}
      </div>
    ));
  };

  return (
    <>
      <Helmet>
  <meta charSet="utf-8" />
  <title>Code Debugger - Debug and Format Code with AI</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/CodeDebugger" />
  <meta
    name="description"
    content="Effortlessly debug and format your code with AI. Paste your code, receive detailed suggestions, and get a corrected version for improved functionality."
  />
  <meta
    name="keywords"
    content="code debugger, code correction, debug code, programming tool, AI code assistant, debug programming errors, coding help, code fix, AI for developers, debug with AI, code suggestions, code analysis, fix programming bugs, error debugging tool, code formatter, code optimization, AI for developers, code review tool, debug programming languages, code formatting tool, AI-driven code fix"
  />
</Helmet>

      <div className="text-center">
        <h1 className="title_lg">Code Debugger</h1>
        <p>Paste your code below to receive debugging assistance</p>
      </div>
      <div className="container summarizer">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="summarizer_item">
              <h4 className="title">Code Input</h4>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">0/2000</span>
                </div>
                <textarea
                  ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="Enter your code here"
                  rows="16"
                  maxLength="2000"
                  className="form-control"
                ></textarea>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>Debug</button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-8">
            <div className="summarizer_item is-disabled">
              <div className="summarizer_item-header">
                <div>
                  <h4 className="title">Generated Output</h4>
                  <p>Here is your generated output by AI</p>
                </div>
              </div>
              <div className="form-group">
                <div className="summernote-container">
                  <div className="note-editor note-frame card">
                    <div className="note-toolbar card-header" role="toolbar">
                      <div className="note-btn-group btn-group note-mybutton">
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm note-btn-rewrite"
                          aria-label="Rewrite"
                          onClick={regenerateText}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handlePrevious}
                          disabled={messages.length === 0 || currentIndex === 0}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handleNext}
                          disabled={messages.length === 0 || currentIndex === messages.length - 1}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div className="note-editing-area">
                      <div
                        className="note-editable card-block"
                        role="textbox"
                        style={{
                          height: "380px",
                          maxHeight: "380px",
                          overflowY: "auto",
                          padding: "10px",
                        }}
                      >
                        {isLoading ? <Loader /> : renderOutput()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summarizer_action">
                <button
                  className="buttonDownload mt-1"
                  onClick={handleDownload}
                  disabled={messages.length === 0}
                >
                  Download
                </button>
                <CopyButton handleCopy={() => copyCode(messages[currentIndex]?.content || "")} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Instructions
        description="This tool helps you debug code snippets. Paste your code and click 'Debug' to receive suggestions and corrections."
        title="Code Debugging Tool"
        step1="Paste your code in the input field."
        step2="Click 'Debug' to analyze and receive corrections."
        step3="Review the output for corrected code or suggestions."
      />
    </>
  );
};

export default CodeDebugger;
