import React, { useState, useRef, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Loader from "./Loader1";
import Loader1 from "./Loader2";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
const ImageCropper = () => {
  const cropperRef = useRef(null);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false); 
  const [loading1, setLoading1] = useState(false);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = () => {
        setTimeout(() => {
          setImage(reader.result);
          setCroppedImage(null);
          setLoading(false);
        }, 2000);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCrop = () => {
    setLoading1(true); 
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const croppedImageBase64 = croppedCanvas.toDataURL();
        setCroppedImage(croppedImageBase64);
        setTimeout(() => {
          setLoading1(false);
        }, 500);
      } else {
        console.error("Failed to get cropped canvas.");
      }
    }
  };

  const handleDownload = () => {
    if (croppedImage) {
      const link = document.createElement("a");
      link.href = croppedImage;
      link.download = "cropped_image.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleReset = () => {
    setImage(null);
    setCroppedImage(null);
  };

  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>Image Cropper - Crop and Edit Your Images Online</title>
  <link rel="canonical" href="http://toolsbag.io/ImageTools/cropper" />
  <meta
    name="description"
    content="Easily crop your images online to fit any size or aspect ratio. Perfect for photographers, designers, and social media users."
  />
  <meta
    name="keywords"
    content="image cropper, crop images, online image cropping, photo cropper, resize images, aspect ratio crop, image cropper, crop images, online image cropping, photo cropper, resize images, aspect ratio crop, free image cropper, crop photos online, best image cropper, image editor, crop photos, crop pictures, resize photos, resize pictures, online photo editor, online picture editor, crop images free, image resizing tool, crop pictures to fit, crop images for social media, image crop tool, free image crop tool, image crop and resize, crop image for Instagram, crop image for Facebook, crop image for Twitter, crop image for LinkedIn, crop image for TikTok, crop image for profile picture, profile picture crop, avatar crop tool, crop for profile photo, crop for cover photo, crop for banner, crop photos to square, crop photos to circle, crop photos for stories, crop images for thumbnails, crop images for YouTube, image crop and rotate, image crop and flip, cut images, cut photos, photo cutting tool, cut pictures online, photo trimming tool, trim photos online, trim images, trim pictures, crop images without losing quality, crop images in high resolution, crop HD images, image cropping for web, image cropping for blog, photo cropping tool, crop photos for free, resize images without losing quality, resize images for web, resize images for social media, resize images for email, resize images for presentations, resize images for printing, resize images for design, free photo cropping tool, crop images to fit aspect ratio, crop images to custom size, crop images to pixels,
     crop images to inches, crop images to cm, crop images to mm, convert images and crop, crop images for e-commerce, crop images for product photos, crop images for website, crop images for ads, crop images for marketing, crop images for graphic design, crop images for print, crop images for digital content, online crop tool, best online crop tool, crop tool for designers, crop tool for photographers, crop tool for social media, crop tool for influencers, crop tool for content creators, crop tool for bloggers, crop tool for freelancers, crop tool for marketers, crop images fast, quick image cropper, bulk image cropping, batch image cropper, crop multiple images, edit multiple images, crop images for free, simple image cropper, easy-to-use image cropper, crop pictures without software, crop images without downloading, crop images on mobile, mobile image cropper, crop images on iPhone, crop images on Android, crop images on tablet, crop images on PC, crop images on Mac, crop images online free, no watermark image cropper, watermark-free image cropper, crop and download images, save cropped images, crop images for professional use, crop images for personal use, crop photos for photography, crop photos for design, crop photos for content creation, crop images for photo books, crop images for portfolios, crop images for CV, crop images for resume, crop images for job applications, crop images for invitations, crop images for flyers, crop images for posters, crop images for brochures, crop images for presentations, crop images for business cards, crop images for digital art, crop images for collages, crop images for school projects, crop images for assignments, crop images for students,
      crop images for teachers, crop images for classrooms, crop images for events, crop images for weddings, crop images for birthdays, crop images for announcements, crop images for newsletters, crop images for websites, crop images for blogs, crop images for videos, crop images for animation, crop images for GIFs, crop GIFs, crop transparent images, crop PNG images, crop JPEG images, crop BMP images, crop TIFF images, crop vector images, crop SVG images, crop images with transparent background, crop images for web development, crop images for app development, image crop tool for developers, crop landscape images, crop portrait images, crop square images, crop rectangle images, crop panoramic images, crop wide images, crop tall images, crop round images, crop oval images, crop triangle images, crop custom shape images, crop with grid lines, crop with guides, crop with overlay, precise image cropper, crop with zoom, crop with pan, crop image borders, crop image corners, crop edges, crop middle section of images, crop sides of images.
"
  />
</Helmet>

      <div className="text-center my-3" style={{ marginTop: "220px" }}>
        <h1 className="title_lg">Image Cropper</h1>
        <p className="tools-description">
          Crop your images and decrease their sizes
        </p>
      </div>

      <div className="container">
        {!image && (
              <div className="uploader-container uploader-container--images">
                {loading ? (
                  <Loader />
                ) : (
                  <form className="file-upload-form">
                    <label htmlFor="file" className="file-upload-label">
                      <div className="file-upload-design">
                        <svg viewBox="0 0 640 512" height="1em">
                          <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                        </svg>
                        <p>Drag and Drop</p>
                        <p>or</p>
                        <span className="browse-button">Browse file</span>
                      </div>
                      <input
                        id="file"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </label>
                  </form>
                )}
              </div>
        )}
        <div className="container">
          {image && !loading && (
            <div className="row">
              <div className="col-lg-8 col-md-12">
                {loading1 && (
                  <div className="loader2" style={{ height: 400, width: "100%" }}>
                    <Loader1 text='Cropping...'/>
                  </div>
                )}
                {!loading1 && (
                  <Cropper
                    src={image}
                    style={{ height: "auto", width: "100%", maxWidth: "100%" }}
                    preview=".img-preview"
                    ref={cropperRef}
                  />
                )}
                <div className="container mb-3 d-flex justify-content-start">
                  {!loading1 && (
                    <>
                      <button
                        onClick={handleCrop}
                        className="normal-button mx-1 my-3"
                      >
                        Crop Image
                      </button>
                      <button
                        className="Reset-button my-3 mx-3"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                {croppedImage && (
                  <div className="preview-box mt-3">
                    <h5 className="mb-2">Cropped Image Preview</h5>
                    <img
                      src={croppedImage}
                      alt="Cropped"
                      className="cropped-image-preview"
                    />
                    <button
                      className="buttonDownload my-2 mx-2"
                      onClick={handleDownload}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Instructions
          description="This tool allows you to crop your images to the desired size."
          title="Crop Your Images"
          step1="Upload the image you want to crop."
          step2="Adjust the crop area to your desired size."
          step3="Click Crop Image to save the cropped image."
        />
    </>
  );
};

export default ImageCropper;
