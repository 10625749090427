import React, { useState, useEffect, useRef } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";
const educationLevels = ["High School", "College", "Postgraduate"];
const Chatgpt = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const outputRef = useRef(null);
  const [paragraphs, setParagraphs] = useState(2);
  const [educationLevel, setEducationLevel] = useState("High School");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  const textAreaRef = useRef(null);
  const outputContainerRef = useRef(null);
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (!isLoading && messages.length > 0 && outputContainerRef.current) {
      outputContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isLoading]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const wordCount = inputValue.trim().split(/\s+/).length;
    if (wordCount < 5) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    const characterCount = inputValue.length;
    document.getElementById("character-counter").innerText = `${characterCount}/100`;
  };

  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    const wordCount = input.trim().split(/\s+/).length;
    if (wordCount < 5) {
      alert("Please enter at least 5 words for the topic.");
      return;
    }
    try {
      setIsLoading(true);
      const prompt = `Write an essay on: "${input}" of "${paragraphs}" for the education level "${educationLevel}"`;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };
  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2); 
    setEducationLevel("High School"); 
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handleParagraphsChange = (event) => {
    const newValue = parseInt(event.target.value);
    setParagraphs(isNaN(newValue) ? 0 : newValue);
  };
  const handleEducationLevelChange = (event) => {
    setEducationLevel(event.target.value);
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>Essay Writer - Write Essays Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/EssayWriter" />
  <meta
    name="description"
    content="Write essays online. Perfect for academic writing, essay generation, and creative essays."
  />
  <meta
    name="keywords"
    content="essay writer, write essays, essay generator, essay writing tool, online essay creator, academic essay writer, generate essays, essay composition tool, creative essay writing, essay structure, thesis writing assistant, persuasive essays, narrative essay writer, write research essays, essay outline tool, fast essay generator, online writing software, personalized essay writing, academic content creation, essay assistance."
  />
</Helmet>


      <div className="text-center">
        <h1 className="title_lg">Essay Writer</h1>
        <p className="mt_3">Write essays on any topic</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Essay Topic</h4>
              <p>What's your essay about?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/60
                  </span>
                </div>
                <textarea
                  ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="i.e., the importance of Laws in a state"
                  rows="5"
                  cols="100"
                  maxLength="60"
                  id="prompt"
                  className={`form-control ${!isValid ? "is-invalid" : ""}`}
                ></textarea>
                {!isValid && (
                  <div className="invalid-feedback" style={{ display: "block", color: "red" }}>
                    <span>Please enter at least 5 words</span>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="number_answers">Number of Paragraphs</label>
                <input
                  type="number"
                  className="form-control my-2"
                  value={paragraphs}
                  onChange={handleParagraphsChange}
                  placeholder="Number of paragraphs"
                />
              </div>
              <div className="form-group">
                <label htmlFor="education_level">Education Level</label>
                <select
                  className="form-control my-2"
                  value={educationLevel}
                  onChange={handleEducationLevelChange}
                >
                  {educationLevels.map((level, index) => (
                    <option key={index} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
        <Instructions
  description="This tool helps you create structured essays based on your topic. Enter your essay topic, and the tool will generate a complete essay outline and content."
  title="Write Your Essay"
  step1="Enter the topic of your essay in the input field."
  step2="The tool will generate an outline and content for your essay."
  step3="Review the generated essay and modify it as needed."
/>
      </div>
    </>
  );
};

export default Chatgpt;
