import React from 'react';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  return (<>
  <Helmet>
  <meta charSet="utf-8" />
  <title>
    Contact Us - Get in Touch with Toolsbag.io for Support and Inquiries
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/contact-us"
  />
  <meta
    name="description"
    content="Get in touch with Toolsbag.io for any support, inquiries, or feedback regarding our AI tools. We are here to assist you."
  />
  <meta
    name="keywords"
    content="contact toolsbag.io, contact us, support, inquiries, AI tools support, customer service, reach out to us, toolsbag.io assistance, support team, AI tools inquiries, customer feedback, help with AI tools, toolsbag.io support services, online support, get help, toolsbag.io contact information, technical support, customer care, assistance with AI tools, toolsbag.io service inquiries, help and support, contact form, email support, phone support, chat support, user inquiries, toolsbag.io help center, customer inquiries, support channels, toolsbag.io customer service, AI tools help, reach our team, contact toolsbag.io team, inquiries about AI tools, AI tools assistance, toolsbag.io support options, user support services, toolsbag.io communication, getting support, toolsbag.io inquiries, customer satisfaction, assistance for users, feedback on toolsbag.io, toolsbag.io user support, toolsbag.io customer inquiries, online customer service, support for AI tools, toolsbag.io help desk, user assistance, contacting toolsbag.io, toolsbag.io contact support, inquiries and feedback, toolsbag.io response team,
     AI tools contact information, customer engagement, support for users, toolsbag.io assistance team, toolsbag.io help resources, toolsbag.io contact details, toolsbag.io client support, questions about AI tools, contacting support, toolsbag.io information center, toolsbag.io help requests, user communication, assistance for inquiries, toolsbag.io user inquiries, AI tools customer support, feedback for toolsbag.io, support resources, toolsbag.io customer help, getting in touch, toolsbag.io reach out, help with inquiries, AI tools contact channels, contact customer service, toolsbag.io service support, support for inquiries, assistance for customers, customer service inquiries, toolsbag.io questions and answers, toolsbag.io feedback channels, toolsbag.io user experience, support via email, AI tools customer engagement, toolsbag.io contact us page, online inquiries, user feedback, toolsbag.io problem resolution, toolsbag.io contact options, reach out for support, customer feedback process, toolsbag.io problem-solving, AI tools feedback, toolsbag.io service inquiries, contacting customer service, AI tools assistance center, support team contact, user experience inquiries, toolsbag.io help communication, toolsbag.io information inquiries, toolsbag.io support help, reaching our support team, inquiries for assistance, toolsbag.io feedback requests, toolsbag.io contact support options, toolsbag.io inquiry response, toolsbag.io user care, toolsbag.io feedback and support"
  />
</Helmet>


    <div style={styles.container}>
      <div className="text-center" >
        <h1 className="title_lg" style={{marginTop:'10px'}}>Contact Us</h1>
        <p className="mt-3 my-3">
        We'd love to hear from you! If you have any questions, feedback, or need support, feel free to get in touch with us:</p></div>
      <div style={styles.contactInfo}>
        <p className='my-2'>Email us at:  <strong> <a style={{textDecoration:'underline'}} href="mailto:your-email@example.com">hazily18@gmail.com</a></strong></p>
        {/* <p>Call us: <strong style={{textDecoration:'underline'}}>+92-301-9785603</strong></p> */}
      </div>
      <p style={styles.paragraph}>We are working on a contact form to make it even easier for you to reach us. Stay tuned—coming soon!</p>
    </div></>
  );
};
const styles = {
  container: {
    maxWidth: '960px',
    margin: '20px auto',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  heading: {
    color: '#06685f',
  },
  paragraph: {
    margin: '15px 0',
  },
  contactInfo: {
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  link: {
    color: '#06685f',
    textDecoration: 'none',
  },
};
export default ContactUs;
