import React from 'react';

const Loader = () => (
  <div className="loader-container">
       <ul>
  <li>
    <div class="loader">
      <div class="child"></div>
    </div>
  </li>

  <li>
    <div class="text1"></div>
  </li>
</ul>
  </div>
);

export default Loader;