import React, { useState, useEffect, useRef } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import Loader from "./Loader";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import CopyButton from "./CopyButton";
import OutputDisplay from "./OutputDisplay";
const messageStyle = {
  padding: "10px",
  borderRadius: "10px",
  maxWidth: "100%",
  marginBottom: "10px",
  alignSelf: "flex-start",
};
const toneOfScript = [
  "Professional",
  "Casual",
  "Witty",
  "Excited",
  "Bold",
  "Sarcastic",
  "Secretive",
  "Dramatic",
  "Grumpy",
];
const ToneOfVoiceChanger = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [tone, settone] = useState("Professional");
  const outputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/2000`;
  };
  const clickHandle = async () => {
    if (!input || !tone ) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    const wordCount = input.trim().split(/\s+/).length;
    if (wordCount < 5) {
      alert("Please enter at least 5 words for the topic.");
      return;
    }
    try {
      setIsLoading(true);
      const prompt = `Change the tone of these line: "${input}" having "${toneOfScript[tone]}" tone`;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; 
      const sentencesPerParagraph = 10; 
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * tone;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });

      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };
  const handleCopy = () => {
    if (messages.length > 0 && currentIndex !== -1) {
      const textToCopy = messages[currentIndex].content;
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          alert("Text copied to clipboard!");
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
        });
    }
  };
  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    settone(2);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handletoneChange = (event) => {
    const selectedTone = event.target.value;
    const toneIndex = toneOfScript.indexOf(selectedTone);
    settone(toneIndex !== -1 ? toneIndex : 0);
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <><Helmet>
    <meta charSet="utf-8" />
    <title>Tone of Voice Changer - Change the Tone of Your Text</title>
    <link rel="canonical" href="http://toolsbag.io/AiTools/ToneOfVoiceChanger" />
    <meta
      name="description"
      content="Easily change the tone of your text online. Perfect for adjusting the style of your writing to suit different audiences and contexts."
    />
    <meta
      name="keywords"
      content="tone of voice changer, change text tone, text tone adjuster, tone converter, online tone changer, adjust writing style, writing tool, change writing tone, text style converter, adapt tone for audience, customize text tone, professional tone writer, casual tone changer, formal tone adjuster, friendly tone creator, persuasive tone changer, empathetic tone converter, creative tone adjuster, writing tone tool, voice adjustment for writing, style guide for tone, improve writing tone, edit text tone online, tone modification tool, text voice changer, tone alignment for content, tone of voice adaptation, audience-specific writing, tone enhancement tool, versatile tone changer, refine text style, personalizing writing tone, tonal variation in writing, text audience targeting, tone matching for communication, writing clarity through tone, emotional tone adjustment, tone sensitivity in writing, tone flexibility for blogs, professional writing tone, casual writing tone, formal writing tone, friendly writing tone, assertive tone adjustment, subtle tone changes, tone of voice in marketing, adjusting tone for social media, tone modulation in copywriting, write with the right tone, tailor writing to audience, tone analysis tool, content tone evaluation, optimizing tone for engagement, voice and tone consistency, enhancing text through tone, tone in branding, nuanced tone adjustment, adjust language tone, articulate tone for effectiveness, tone refinement for clarity."
    />
  </Helmet>
  
    <div className="text-center">
    <h1 className="title_lg">Tone of Voice Changer in Text</h1>
    <p className="mt_5">Change the tone of voice in Text</p>
  </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Your content</h4>
              <p>What's your content for which you wanna change tone?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/2000
                  </span>
                </div>
                <textarea
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="enter your text"
                  rows="10"
                  cols="100"
                  maxLength="2000"
                  id="prompt"
                  className="form-control"
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="education_level">Tone of voice:</label>
                <select
                  className="form-control my-2"
                  value={toneOfScript[tone]}
                  onChange={handletoneChange}
                >
                  {toneOfScript.map((level, index) => (
                    <option key={index} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
      </div>
      <Instructions
  description="This tool allows you to adjust the tone of your text to better fit your audience or purpose. Enter your text, choose the desired tone, and receive a revised version."
  title="Change the Tone of Your Text"
  step1="Enter the text you want to modify in the input field."
  step2="Select the desired tone (e.g., formal, casual, friendly, etc.)."
  step3="Once you submit, the tool will provide a revised version of your text in the chosen tone."
/>
    </>
  );
};

export default ToneOfVoiceChanger;
