import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Loader from './Loader1';
import { useLocation } from "react-router-dom"; 
import { Helmet } from 'react-helmet';
import Instructions from './Instructions';
  
const ExcelToCsvConverter = () => {
  const [excelData, setExcelData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState('');
const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleExcelChange = (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];
    setExcelData(selectedFile);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const convertExcelToCsv = () => {
    setLoading(true);
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const csvResult = XLSX.utils.sheet_to_csv(worksheet);
        setCsvData(csvResult);
      };

      reader.readAsArrayBuffer(excelData);
    } catch (error) {
      console.error('Error converting Excel to CSV:', error);
      alert('Error converting Excel to CSV. Please check the Excel format.');
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const downloadCsv = () => {
    if (csvData) {
      const blob = new Blob([csvData], { type: 'text/csv' });

      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'converted_data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (<>
  <Helmet>
  <meta charSet="utf-8" />
  <title>Excel to CSV Converter - Convert Excel Files to CSV Online</title>
  <link rel="canonical" href="http://toolsbag.io/CSVTools/Excel-to-CSV-Converter" />
  <meta
    name="description"
    content="Easily convert your Excel spreadsheets into CSV format online. Ideal for data export, sharing, and managing datasets."
  />
  <meta
    name="keywords"
    content="excel to csv, convert excel to csv, xls to csv converter, xlsx to csv, spreadsheet to csv, online excel to csv converter, free excel to csv tool, fast excel to csv conversion, excel to csv format, convert spreadsheet to csv, data export to csv, xls to csv online, xlsx to csv online, online spreadsheet conversion, convert data to csv format, excel file to csv converter, spreadsheet data to csv, excel data export, csv file generation from excel, online data transformation, excel spreadsheet exporter, excel workbook to csv, csv conversion for spreadsheets, convert excel files online, csv export tool, xls to csv transformation, spreadsheet management, convert xlsx to csv online, data handling tools, csv for data analysis, fast spreadsheet conversion, online csv generator, free online csv converter, convert excel for data sharing, excel to csv data extraction, excel sheets to csv, convert excel documents to csv, efficient excel to csv conversion, online csv export, spreadsheet to csv format converter, convert excel files to csv documents, excel to csv data migration, csv file maker from excel, xls to csv document converter, online spreadsheet tools, spreadsheet data conversion, convert excel rows to csv, excel format to csv, csv creation from excel, data organization tools, easy excel to csv tool, export excel data to csv, convert xlsx documents to csv, csv files from excel spreadsheets, convert excel for web use, data conversion tools, xlsx file to csv converter, free online spreadsheet converter, csv data handling,
     excel to csv service, spreadsheet splitting to csv, excel formatting to csv, xls data to csv converter, online data processing, convert excel formulas to csv, csv generator from spreadsheets, convert large excel files to csv, csv data processing tool, excel table to csv format, data interchange using csv, xlsx to csv transformation, data export for analysis, online excel file converter, csv document generator from excel, efficient data sharing, excel database to csv format, convert spreadsheets for analytics, online file conversion tools, export data from excel to csv, excel cell data to csv, batch excel to csv converter, csv text file creation from excel, free online excel file converter, online spreadsheet export, fast file conversion from excel to csv, csv data for programming, convert excel workbooks to csv, manage data with csv, xls to csv processing, excel to csv bulk conversion, data sharing via csv, online data formatting tools, spreadsheet to csv file converter, csv output from excel sheets, convert workbook to csv format, xlsx files to csv conversion, csv conversion utility, convert excel files for database, transform excel data to csv, online excel to csv processor, convert excel documents for export, spreadsheet conversion to csv online, online excel file management, xls data export, data processing using csv, excel to csv web application, simple spreadsheet conversion, free excel data extractor to csv, convert excel reports to csv, online data analysis tools, csv output for data analysis, excel format data sharing, convert sheets to csv documents."
  />
</Helmet>


  <div className="text-center">
        <h1 className="title_lg">Excel to CSV converter</h1>
        <p className="mt_5">Convert your Excel files to CSV</p>
      </div>
      <div className='container'>
            <div className="uploader-container uploader-container--images">
            {loading ? (
                <Loader />
              ) : !excelData ? (<form className="file-upload-form">
                <label htmlFor="file" className="file-upload-label">
                  <div className="file-upload-design">
                    <svg viewBox="0 0 640 512" height="1em">
                      <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                    </svg>
                    <p>Drag and Drop</p>
                    <p>or</p>
                    <span className="browse-button">Browse file</span>
                  </div>
                  <input
                    id="file"
                    type="file" accept=".xlsx, .xls" onChange={handleExcelChange}
                  />
                </label>
              </form> ) : (
                <>
                  <div
                    style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                    className="my-1"
                  >
                    {excelData && `${excelData.name}`}
                  </div>
                  <div className="container">
                    <button className="normal-button" onClick={convertExcelToCsv}>
                      Convert
                    </button>
                    {csvData && (
                      <button className="buttonDownload mx-1" onClick={downloadCsv}>
                        Download CSV
                      </button>
                    )}
                    <button className="Reset-button" onClick={() => setExcelData(null)}>
                      Reset
                    </button>
                  </div>
                </>
              )}
            </div>
        </div>
        <Instructions
  description="This tool allows you to convert your Excel files into CSV format quickly and easily. Upload your Excel file, and download it as a CSV document in just a few clicks."
  title="Convert Excel to CSV"
  step1="Upload the Excel file you want to convert to CSV."
  step2="The tool will automatically process the Excel file and convert it into CSV format."
  step3="Once converted, click Download CSV to save your file."
/>
        </>
  );
};

export default ExcelToCsvConverter;
