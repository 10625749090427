import React, { useState, useEffect } from "react";
import { PDFDocument } from "pdf-lib";
import Loader from "./Loader1";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";

const PdfSplitter = () => {
  const [file, setFile] = useState(null);
  const [startPage, setStartPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [endPage, setEndPage] = useState(1);
  const [splitPdfBlob, setSplitPdfBlob] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleFileChange = async (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    const pdfBytes = await selectedFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const pageCount = pdfDoc.getPageCount();
    setTotalPages(pageCount);
    setEndPage(pageCount);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleStartPageChange = (event) => {
    setStartPage(parseInt(event.target.value, 10));
  };

  const handleEndPageChange = (event) => {
    setEndPage(parseInt(event.target.value, 10));
  };

  const splitPdf = async () => {
    try {
      setLoading(true);
      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      if (
        startPage < 1 ||
        endPage > pdfDoc.getPageCount() ||
        startPage > endPage
      ) {
        alert("Invalid page range");
        return;
      }
      const adjustedStartPage = startPage - 1;
      const adjustedEndPage = endPage - 1;
      const newPdfDoc = await PDFDocument.create();
      const copiedPages = await newPdfDoc.copyPages(
        pdfDoc,
        Array.from(
          { length: adjustedEndPage - adjustedStartPage + 1 },
          (_, i) => adjustedStartPage + i
        )
      );
      copiedPages.forEach((page) => newPdfDoc.addPage(page));
      const newPdfBytes = await newPdfDoc.save();
      const blob = new Blob([newPdfBytes], { type: "application/pdf" });
      setSplitPdfBlob(blob);
    } catch (error) {
      console.error("Error splitting PDF:", error);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const downloadSplitPdf = () => {
    if (splitPdfBlob) {
      const url = URL.createObjectURL(splitPdfBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "split_pdf.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleReset = () => {
    setFile(null);
    setStartPage(1);
    setEndPage(1);
    setTotalPages(null);
    setSplitPdfBlob(null);
  };

  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>PDF Splitter - Split PDF Files Online</title>
  <link rel="canonical" href="http://toolsbag.io/PdfTools/PdfSplitter" />
  <meta
    name="description"
    content="Easily split your PDF files into multiple documents online. Perfect for extracting specific pages or separating sections of large files."
  />
  <meta
    name="keywords"
    content="pdf splitter, split pdf online, divide pdf, separate pdf pages, split pdf into multiple files, pdf page extractor, extract pages from pdf, online pdf splitter, free pdf splitting tool, pdf file division, cut pdf documents, pdf section separator, split large pdf files, pdf page removal, page separation tool, online document splitting, manage pdf files, split pdf by size, pdf splitting software, fast pdf splitter, merge and split pdf, divide large pdf documents, extract pdf pages easily, online pdf tools, document management, pdf section extraction, pdf files divider, split multi-page pdf, easy pdf page splitter, pdf splicing tool, secure pdf splitting, online pdf file cutter, efficient pdf page extraction, user-friendly pdf splitter, simple pdf splitting process, automated pdf splitting, online tools for pdf management, quick pdf sectioning, separate pdf into individual files, free pdf extraction tool, precise pdf page splitter, pdf extraction and splitting, cloud-based pdf splitter, document division for pdf, organize pdf sections, extract specific pdf pages, pdf splitting service, PDF files to individual documents, split by page range in pdf, merge and split documents, extract content from pdf, easy-to-use pdf splitter, online page separation tool, fast and reliable pdf splitter, pdf management tool, split scanned pdf files, pdf documents organizer, split pdf for sharing,
     pdf content extraction, divide pdf for easier handling, online tools for document splitting, split files for email, PDF joiner and splitter, document section management, efficient document splitting solutions, online page cutter, split pdf for presentations, create separate pdf files, separate pages from pdf documents, manage pdf pages, pdf file manipulation, online pdf section cutter, split pdf for printing, extract content from pdf documents, free online pdf page extractor, pdf organizer for large files, cut pdf pages into smaller files, page segmentation tool for pdf, divide pdf files for archiving, split pages from scanned pdf, pdf page splitter with drag and drop, easily divide pdf documents, pdf separation options, extract necessary pdf pages, fast and easy pdf dividing tool, online document extraction service, pdf page selector, cut and separate pdf pages, pdf manipulation software, simple online pdf divider, divide pdf for workflow efficiency, split pdf files securely, PDF section management online, split pdf for easier navigation, pdf page splitting tool, separate important pages from pdf, quick document division, split pdf to share sections, pdf page organization tool, effective pdf management tools, online document splitting solutions, advanced pdf page extraction."
  />
</Helmet>


      <div className="text-center">
        <h1 className="title_lg">PDF Splitter</h1>
        <p className="mt_15 my-3">Split your PDF files</p>
      </div>
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="col-xl-10">
            <div className="uploader-container uploader-container--images">
              {loading ? (
                <Loader />
              ) : !file ? (
                <form className="file-upload-form">
                  <label htmlFor="file" className="file-upload-label">
                    <div className="file-upload-design">
                      <svg viewBox="0 0 640 512" height="1em">
                        <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                      </svg>
                      <p>Drag and Drop</p>
                      <p>or</p>
                      <span className="browse-button">Browse file</span>
                    </div>
                    <input
                      id="file"
                      accept=".pdf"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </label>
                </form>
              ) : (
                <>
                  <div
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                    className="my-1"
                  >
                    {file && file.name} ({totalPages} pages)
                  </div>
                  <div className="input-container">
                    <input
                      id="input-start"
                      required=""
                      type="number"
                      value={startPage}
                      onChange={handleStartPageChange}
                      className="form-control text-input"
                      min="1"
                      max={totalPages}
                    />
                    <label htmlFor="input-start" className="label">
                      Start Page Number:
                    </label>
                    <div className="underline"></div>
                  </div>
                  <div className="input-container">
                    <input
                      id="input-end"
                      required=""
                      type="number"
                      value={endPage}
                      onChange={handleEndPageChange}
                      className="form-control text-input"
                      min="1"
                      max={totalPages}
                    />
                    <label htmlFor="input-end" className="label">
                      End Page Number:
                    </label>
                    <div className="underline"></div>
                  </div>
                  <div className="container">
                    <button className="normal-button " onClick={splitPdf}>
                      Split PDF
                    </button>
                    {splitPdfBlob && (
                      <button
                        className="buttonDownload mx-1"
                        onClick={downloadSplitPdf}
                      >
                        Download Split PDF
                      </button>
                    )}
                    <button className="Reset-button " onClick={handleReset}>
                      Reset
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Instructions
  description="This tool allows you to split your PDF document into separate pages or sections. Upload your PDF, choose how you want to split it, and download the resulting files."
  title="Split Your PDF"
  step1="Upload the PDF file you want to split."
  step2="Select the pages or sections you want to extract from the PDF."
  step3="Once split, click Download to save your separated PDF files."
/>
      </div>
    </>
  );
};

export default PdfSplitter;
