import React, { useState } from "react";
import { cards as ImageToolsCards } from "./ImageTools";
import { cards as PdfTools } from "./PdfTools";
import { cards as AiTools } from "./AiTools";
import { Link } from "react-router-dom";
const PortfolioSection = () => {
  const [currentFilter, setCurrentFilter] = useState("*"); // Initial filter set to '*'
  const handleFilterClick = (filter) => {
    setCurrentFilter(filter);
  };
  const getFilteredCards = () => {
    if (currentFilter === "*") {
      return [...PdfTools, ...ImageToolsCards, ...AiTools];
    } else if (currentFilter === "filter-app") {
      return PdfTools;
    } else if (currentFilter === "filter-product") {
      return ImageToolsCards;
    } else if (currentFilter === "filter-product1") {
      return AiTools;
    }
    return [];
  };

  return (
    <section id="portfolio my-5" className="portfolio sections-bg" style={{ margin: "20px", padding: "30px" }}>
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Tools Bag</h2>
          <p>Select the tool from your bag</p>
        </div>
        <div className="container1 my-3">
          <div className="tabs">
            <input
              onClick={() => handleFilterClick("*")}
              className={currentFilter === "*" ? "filter-active" : ""}
              type="radio"
              id="radio-1"
              name="tabs"
              defaultChecked={currentFilter === "*"} // Use defaultChecked
            />
            <label className="tab" htmlFor="radio-1">
              All
            </label>
            <input
              onClick={() => handleFilterClick("filter-app")}
              className={currentFilter === "filter-app" ? "filter-active" : ""}
              type="radio"
              id="radio-2"
              name="tabs"
              defaultChecked={currentFilter === "filter-app"} // Use defaultChecked
            />
            <label className="tab" htmlFor="radio-2">
              PDF
            </label>
            <input
              onClick={() => handleFilterClick("filter-product")}
              className={currentFilter === "filter-product" ? "filter-active" : ""}
              type="radio"
              id="radio-3"
              name="tabs"
              defaultChecked={currentFilter === "filter-product"} // Use defaultChecked
            />
            <label className="tab" htmlFor="radio-3">
              Image
            </label>
            <input
              onClick={() => handleFilterClick("filter-product1")}
              className={currentFilter === "filter-product1" ? "filter-active" : ""}
              type="radio"
              id="radio-4"
              name="tabs"
              defaultChecked={currentFilter === "filter-product1"} // Use defaultChecked
            />
            <label className="tab" htmlFor="radio-4">
              AI
            </label>
            <span className="glider"></span>
          </div>
        </div>
        <div className="row gy-4 portfolio-container">
          {getFilteredCards().map((item, index) => (
            <PortfolioItem
              key={index}
              category={item.category}
              icon={item.icon}
              title={item.title}
              details={item.details}
              path={item.path}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

// Component for each portfolio item
const PortfolioItem = ({ category, icon, title, details, path }) => {
  return (
    <div className={`col-xl-4 col-md-6 portfolio-item ${category}`}>
      <div className="portfolio-wrap">
        <div className="portfolio-info">
          <h4>
            <Link to={path} >
              <div className="icon-wrapper">
                {icon && (
                  <img
                    src={icon}
                    style={{
                      width: "24px",
                      height: "24px",
                      fill: "#06685f",
                      transition: "all 0.6s cubic-bezier(0.23, 1, 0.320, 1)",
                    }}
                    alt="SVG Icon"
                    className="icon mx-1"
                  />
                )}
                <span>{title}</span>
              </div>
            </Link>
          </h4>
          <p>{details}</p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;
