import React, { useState, useEffect } from "react";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import PptxGenJS from "pptxgenjs";
import { saveAs } from "file-saver";
import "pdfjs-dist/build/pdf.worker.entry"; // PDF.js worker
import Loader from "./Loader1"; // Ensure you have this component
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
const PdfToPptConverter = () => {
  const [file, setFile] = useState(null);
  const [isConverting, setIsConverting] = useState(false);
  const [error, setError] = useState(null);
  const [convertedBlob, setConvertedBlob] = useState(null); // State to hold the converted PPT blob
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile || selectedFile.type !== "application/pdf") {
      setError("Please select a valid PDF file.");
      return;
    }
    setFile(selectedFile);
    setError(null);
    setConvertedBlob(null); // Reset the converted file when a new file is uploaded
  };

  const handleConvert = async () => {
    if (!file) return;
    setIsConverting(true);
    setError(null);

    try {
      const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
      const pptx = new PptxGenJS();

      const slideWidth = pptx.width;   // Default is 10 inches
      const slideHeight = pptx.height; // Default is 5.625 inches

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 2 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        await page.render({ canvasContext: context, viewport }).promise;
        const imgData = canvas.toDataURL("image/jpeg");

        const pageAspectRatio = canvas.width / canvas.height;
        let imgWidth, imgHeight;

        if (pageAspectRatio > slideWidth / slideHeight) {
          imgWidth = slideWidth;
          imgHeight = slideWidth / pageAspectRatio;
        } else {
          imgHeight = slideHeight;
          imgWidth = slideHeight * pageAspectRatio;
        }

        const slide = pptx.addSlide();
        slide.addImage({
          data: imgData,
          x: (slideWidth - imgWidth) / 2,  
          y: (slideHeight - imgHeight) / 2, 
          w: imgWidth,
          h: imgHeight,
        });
      }

      const pptxBlob = await pptx.write("blob");
      setConvertedBlob(pptxBlob); // Store the converted file blob
    } catch (error) {
      console.error("Error during conversion:", error);
      setError("Failed to convert PDF to PPT. Please try again.");
    } finally {
      setIsConverting(false);
    }
  };

  const handleDownload = () => {
    if (convertedBlob) {
      saveAs(convertedBlob, file.name.replace(".pdf", ".pptx"));
    }
  };

  return (<>
 <Helmet>
    <meta charSet="utf-8" />
    <title>PDF to PPT - Convert PDF to PowerPoint Slides Online</title>
    <link rel="canonical" href="http://toolsbag.io/PdfTools/pdftoppt" />
    <meta
        name="description"
        content="Easily convert your PDF documents into PowerPoint (PPT) slides online. Perfect for turning PDF pages into editable PowerPoint presentations."
    />
    <meta
        name="keywords"
        content="pdf to ppt, convert pdf to ppt, pdf to powerpoint, pdf to ppt converter, online ppt converter, pdf to slides, convert pdf to slides, pdf to presentation, pdf to ppt online, ppt conversion tool, PDF to PowerPoint, PDF to PPT conversion, PDF to PPT online tool, online PDF to PPT converter, free PDF to PPT converter, PDF to PPT software, PPT from PDF, extract slides from PDF, convert PDF pages to PPT, PDF to PowerPoint slides, PDF to PPT format, PDF to PPT file, PDF slide converter, PDF slide maker, online document converter, PowerPoint presentation from PDF, edit PDF in PPT, PDF conversion tool, create PPT from PDF, PDF to PowerPoint converter free, fast PDF to PPT conversion, accurate PDF to PPT, document conversion service, convert PDF files to PPT, PDF to PPT conversion software, online PDF converter, PDF to PowerPoint tools, PPT from PDF files, PDF to PowerPoint online, convert PDF to editable PPT, PDF to slide deck, online PDF to presentation, convert to PPT online, online presentation converter, best PDF to PPT converter, reliable PDF to PPT, PDF to PPT technology, convert PDF documents, PDF to slides converter, upload PDF to PPT, convert images from PDF to PPT, PDF document to PowerPoint, PDF to PPT tool, online PDF presentation, PPT generator from PDF, convert PDF to editable slides, extract content from PDF to PPT, online PDF to PPT conversion, user-friendly PDF to PPT, PDF to PPT service, create PowerPoint from PDF, convert PDF documents to PPT,
         digital PDF to PPT, easy PDF to PPT conversion, fast online PDF to PPT, efficient PDF to PowerPoint, transform PDF to PPT, instant PDF to PPT, drag and drop PDF to PPT, online file converter, PDF to PPT web app, cloud-based PDF to PPT, PDF to PPT desktop software, free online PDF tools, document editing tool, online file conversion, secure PDF to PPT conversion, PDF to PPT converter free online, convert PDF for presentation, versatile PDF to PPT converter, online conversion service, advanced PDF to PPT conversion, simple PDF to PPT tool, convert PDF for PowerPoint, PDF to presentation tool, PPT file from PDF, document preparation tool, interactive PDF to PPT, PDF to slide presentation, PDF to editable PowerPoint, professional PDF to PPT service, PDF slide extraction, PPT formatting from PDF, online PDF to PPT service, best online PDF tools, streamlined PDF to PPT process, PDF to PPT functionalities, converting PDF pages to slides, PPT slideshow from PDF, shareable PDF to PPT, document sharing tool, PDF to PowerPoint creation, easy-to-use PDF to PPT converter, bulk PDF to PPT conversion, multi-file PDF to PPT, PDF to PPT app, one-click PDF to PPT conversion, PDF to PowerPoint editor, simple document converter, comprehensive PDF to PPT tool, flexible PDF to PPT options"
    />
</Helmet>


    <div className="container">
      <div className="text-center my-3">
        <h1 className="title_lg">PDF to PPT slides Converter</h1>
        <p className="tools-description">
          Convert your PDF document to PPT slides
        </p>
      </div>
      <div className="uploader-container container">
        {!isConverting && !file ? (
          <form className="file-upload-form">
            <label htmlFor="file-upload" className="file-upload-label">
              <div className="file-upload-design">
                <svg viewBox="0 0 640 512" height="1em">
                  <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                </svg>
                <p>Drag and Drop</p>
                <p>or</p>
                <span className="browse-button">Browse File</span>
              </div>
              <input
                type="file"
                accept="application/pdf"
                id="file-upload"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </label>
          </form>
        ) : (
          <>
            <div className="file-info">
              {file && (
                <p>
                  <strong>{file.name}</strong>
                </p>
              )}
            </div>
            <button
              className="normal-button my-1"
              onClick={handleConvert}
              disabled={isConverting}
            >
              Convert to PPT
            </button>
            {convertedBlob && (
              <button className="buttonDownload my-1" onClick={handleDownload}>
                Download PPT
              </button>
            )}
            <button onClick={() => setFile(null)} className="Reset-button">
              Reset
            </button>
          </>
        )}
        {error && <p className="error">{error}</p>}
        {isConverting && <Loader />}
      </div>
      <Instructions
          description="This tool allows you to convert PDF documents into PowerPoint (PPT) slides. Simply drag and drop your PDF file or click to select it. After adding the PDF, click Convert to PPT and then download the PowerPoint file with each page of the PDF as a separate slide."
          title="Convert a PDF to PPT"
          step1=" Select the PDF document you want to convert to PPT format."
          step2=" Upload the PDF file from your device (Mac, PC, Android, or iPhone)."
          step3="Click Convert to PPT to start the conversion. Download the converted PPT slides, and adjust the sizes of PDF pages in slides."
        />
    </div>
  </>
  );
};

export default PdfToPptConverter;
