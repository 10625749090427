import React, { useState, useRef, useEffect } from "react";
import pptxgen from "pptxgenjs";
import Loader from "./Loader2";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
const ImageToPPTConverter = ({ resetKey }) => {
  const [fileList, setFileList] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const resetComponent = () => {
      setFileList([]);
      setIsButtonDisabled(true);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    };
    resetComponent();
    return () => {
      fileList.forEach((file) => URL.revokeObjectURL(file.url));
    };
  }, [resetKey]);
  const handleClear = () => {
    setFileList([]);
    setIsButtonDisabled(true);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleFileInputChange = (event) => {
    setLoading(true);
    const files = event.target.files;
    const newFileList = Array.from(files).map((file) => ({
      file: file,
      url: URL.createObjectURL(file),
    }));
    setFileList([...fileList, ...newFileList]);
    setIsButtonDisabled(false);
    setLoading(false);
  };

  const handleDeleteClick = (index) => {
    setFileList((prevList) => {
      const newList = [...prevList];
      URL.revokeObjectURL(newList[index].url);
      newList.splice(index, 1);
      return newList;
    });
  };

  const convertToPPT = async () => {
    const pres = new pptxgen();
    const addSlideForImage = async (imagePath) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          const slide = pres.addSlide();
          const slideWidth = pres.width;
          const slideHeight = pres.height;
          const imageWidth = img.width / 100; 
          const imageHeight = img.height / 100; 
          const x = (slideWidth - imageWidth) / 2;
          const y = (slideHeight - imageHeight) / 2;
          const imageOptions = {
            path: imagePath.url,
            x,
            y,
            w: imageWidth,
            h: imageHeight,
          };
          slide.addImage(imageOptions);
          resolve();
        };
        img.src = imagePath.url;
      });
    };
    await Promise.all(fileList.map(addSlideForImage));
    return pres;
  };
  const handleConvertClick = async () => {
    setLoading(true);
    console.log("Presentation created successfully!");
    setLoading(false);
  };
  const handleDownloadClick = async () => {
    const presentation = await convertToPPT();
    const outputFileName = generateOutputFileName();
    presentation.writeFile({ fileName: outputFileName }, (err) => {
      if (err) {
        console.error(err);
      } else {
        console.log("Presentation downloaded successfully!");
      }
    });
  };
  const handleAddMoreFiles = () => {
    fileInputRef.current.click();
  };
  const generateOutputFileName = () => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace(/[-T]/g, "")
      .replace(":", "");
    return `output_${formattedDate}.pptx`;
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Image to PowerPoint Converter - Convert Images to PPT Online
        </title>
        <link
          rel="canonical"
          href="http://toolsbag.io/ImageTools/Image-to-PowerPoint-Converter"
        />
        <meta
          name="description"
          content="Easily convert your images into PowerPoint slides online. Ideal for presentations, business reports, and creative projects."
        />
        <meta
          name="keywords"
          content="image to ppt, convert images to powerpoint, image to ppt converter, create powerpoint from images, convert images to slides,
          image to ppt, convert images to powerpoint, image to ppt converter, create powerpoint from images, convert images to slides, image to ppt online, convert jpg to ppt, convert png to ppt, convert gif to ppt, convert bmp to ppt, image to slide converter, images to ppt slides, online ppt creator from images, image to powerpoint slide maker, make presentations from images, photo to ppt converter, convert images into presentation slides, photo slideshow to ppt, image to presentation tool, images for PowerPoint, image-based presentations, jpg to ppt converter, png to ppt converter, gif to ppt converter, online presentation from images, create PowerPoint from pictures, convert photos to PowerPoint slides, PowerPoint image import tool, convert image sequence to PowerPoint, batch convert images to ppt, bulk image to ppt conversion, photo album to ppt, images for business presentations, create PowerPoint slideshows from images, online image to presentation maker, image to ppt software, free image to ppt converter, fast image to ppt converter, drag and drop images to ppt, add pictures to PowerPoint, image-to-slide converter, convert multiple images to ppt, photo presentation tool, jpg to PowerPoint, png to PowerPoint, gif to PowerPoint, image to Microsoft PowerPoint, export images to PowerPoint, import images to PowerPoint, 
          photo-based PowerPoint presentation
"
        />
      </Helmet>

      <div className="text-center my-1">
        <h1 className="title_lg">Image to PPT Converter</h1>
        <p className="mt_5">
          Convert your Image to Powerpoint slides
        </p>
      </div>
      <div className="container">
        <div className="uploader-container uploader-container--images container">
          <form className="file-upload-form">
            <label htmlFor="fileInput" className="file-upload-label">
              <div className="file-upload-design">
                <svg viewBox="0 0 640 512" height="1em">
                  <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                </svg>
                <p>Drag and Drop</p>
                <p>or</p>
                <span className="browse-button">Browse file</span>
              </div>
              <input
                id="file"
                className="form-label"
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
                multiple
                ref={fileInputRef}
              />
            </label>
          </form>
        </div>
      </div>
      {fileList.map((file, index) => (
        <div key={index} className="file-upload-container mb-3 container">
          <div className="image-container">
            <div className="image-details">{file.file.name}</div>
            <button
              className="deleteButton"
              onClick={() => handleDeleteClick(index)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 50 59"
                className="bin"
              >
                <path
                  fill="#B5BAC1"
                  d="M0 7.5C0 5.01472 2.01472 3 4.5 3H45.5C47.9853 3 50 5.01472 50 7.5V7.5C50 8.32843 49.3284 9 48.5 9H1.5C0.671571 9 0 8.32843 0 7.5V7.5Z"
                ></path>
                <path
                  fill="#B5BAC1"
                  d="M17 3C17 1.34315 18.3431 0 20 0H29.3125C30.9694 0 32.3125 1.34315 32.3125 3V7.5H17V3Z"
                ></path>
                <path
                  fill="#B5BAC1"
                  d="M15.5003 49.5402C14.1196 49.5402 13.0003 48.4209 13.0003 47.0402V24.9602C13.0003 23.5795 14.1196 22.4602 15.5003 22.4602C16.881 22.4602 18.0003 23.5795 18.0003 24.9602V47.0402C18.0003 48.4209 16.881 49.5402 15.5003 49.5402ZM29.5003 47.0402C29.5003 48.4209 30.6196 49.5402 32.0003 49.5402C33.381 49.5402 34.5003 48.4209 34.5003 47.0402V24.9602C34.5003 23.5795 33.381 22.4602 32.0003 22.4602C30.6196 22.4602 29.5003 23.5795 29.5003 24.9602V47.0402Z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
                <path
                  fill="#B5BAC1"
                  d="M2 13H48L47.6742 21.28H2.32031L2 13Z"
                ></path>
              </svg>
              <span className="tooltip">Delete</span>
            </button>
          </div>
        </div>
      ))}
      <div className="container my-5 button-group">
  {fileList.length > 0 && (
    <div className="d-flex justify-content-between align-items-center mb-3 button-container">
      <button
        onClick={handleAddMoreFiles}
        type="button"
        className="addbutton mx-1"
      >
        <span className="button__text">Add More</span>
        <span className="button__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeLinecap="round"
            stroke="currentColor"
            height="24"
            fill="none"
            className="svg"
          >
            <line y2="19" y1="5" x2="12" x1="12"></line>
            <line y2="12" y1="12" x2="19" x1="5"></line>
          </svg>
        </span>
      </button>
      <button
        className="btn normal-button"
        onClick={handleConvertClick}
        disabled={isButtonDisabled}
      >
        {!loading ? <div>Convert to PPT</div> : <Loader text='Converting...'/>}
      </button>
      <button
        className="btn buttonDownload mx-1"
        onClick={handleDownloadClick}
        disabled={isButtonDisabled}
      >
        Download PPT
      </button>
      <button className="Reset-button" onClick={handleClear}>
        Reset
      </button>
    </div>
  )}
  <Instructions
          description=" This tool allows you to convert images to a PowerPoint presentation.
          Simply drag and drop your image files or click to select them. After
          adding the images, click Convert to PPT to create a PowerPoint
          presentation with each image on a separate slide. Once converted, you
          can download the PowerPoint file using the Download PPT button."
          title=" Convert a Image to PPT images"
          step1=" Upload the images you want to convert in PPT."
          step2="Click on convert button."
          step3= "Click Save PPT to download your image with text."
        />
      </div>
    </>
  );
};
const styles = `
  .file-upload-label {
    cursor: pointer;
  }
  .form-label {
    display: none;
  }
`;
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default ImageToPPTConverter;
