import React, { useState, useEffect } from "react";
import mammoth from "mammoth";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { saveAs } from "file-saver";
import Loader from "./Loader1";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
  Helvetica: {
    normal: "Helvetica.ttf",
    bold: "Helvetica-Bold.ttf",
  },
};
const WordToPdfConverter = () => {
  const [files, setFiles] = useState([]);
  const [isConverting, setIsConverting] = useState(false);
  const [error, setError] = useState(null);
  const [convertedBlob, setConvertedBlob] = useState(null);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length === 0) {
      setError("No file selected");
      return;
    }
    setFiles(selectedFiles);
    setError(null);
    setConvertedBlob(null);
  };
  const handleReset = () => {
    setFiles([]);
    setError(null);
    setConvertedBlob(null);
  };
  const handleConvert = async () => {
    if (files.length === 0) return;
    setIsConverting(true);
    setError(null);
    try {
      const file = files[0];
      const arrayBuffer = await file.arrayBuffer();
      const { value: html } = await mammoth.convertToHtml({ arrayBuffer });
      const pdfContent = htmlToPdfmake(html);
      const docDefinition = {
        content: pdfContent,
        defaultStyle: {
          font: "Roboto",
          fontSize: 12,
        },
        styles: {
          header: {
            fontSize: 14,
            bold: true,
          },
        },
      };
      pdfMake.createPdf(docDefinition).getBlob((blob) => {
        setConvertedBlob(blob);
      });
    } catch (error) {
      console.error("Error converting file:", error);
      setError("Failed to convert files. Please try again.");
    } finally {
      setIsConverting(false);
    }
  };
  const handleDownload = () => {
    if (convertedBlob) {
      saveAs(convertedBlob, `${files[0].name.replace(/\.docx$/, "")}.pdf`);
    }
  };
  return (
    <>
      <Helmet>
  <meta charSet="utf-8" />
  <title>Word to PDF Converter - Convert Word Documents (DOC, DOCX) to PDF Online</title>
  <link
    rel="canonical"
    href="http://toolsbag.io/DocumentTools/WordToPdfConverter"
  />
  <meta
    name="description"
    content="Easily convert Word documents (DOC, DOCX) to PDF format online. Perfect for creating professional PDFs from Word files for sharing, printing, or archiving."
  />
  <meta
    name="keywords"
    content="word to pdf, convert word to pdf, doc to pdf, docx to pdf, online pdf converter, word to pdf converter, word file to pdf, free word to pdf, word to pdf online, best word to pdf converter, Word to PDF, convert Word to PDF, DOC to PDF, DOCX to PDF, online PDF converter, Word to PDF converter, free Word to PDF, Word to PDF online, best Word to PDF converter, DOC file to PDF, DOCX file to PDF, convert DOC to PDF, convert DOCX to PDF, Word file to PDF, Word document to PDF, Word to PDF free, fast Word to PDF, Word to PDF high quality, online Word document converter, create PDF from Word, convert Word to PDF online free, convert Word to PDF no registration, Word to PDF secure, convert DOC to PDF online, convert DOCX to PDF online, Word to PDF for business, Word to PDF for professional use, export Word as PDF, save Word as PDF, print Word as PDF, convert Microsoft Word to PDF, convert Word files to PDF, Word document to PDF online tool, best free Word to PDF converter, convert Word to PDF instantly, Word to PDF with one click, bulk Word to PDF converter, batch Word to PDF, convert multiple Word documents to PDF, merge Word to PDF, split Word into PDFs, convert Word pages to PDF, high-resolution Word to PDF, Word to PDF for email, Word to PDF for presentations, share Word as PDF, export Word to PDF for printing, Word to PDF for archiving, DOC to PDF converter online, DOCX to PDF converter online, online DOC to PDF converter, online DOCX to PDF converter, convert Word to PDF on mobile, mobile Word to PDF, convert Word to PDF for iPhone, convert Word to PDF for Android, convert Word to PDF for Mac
"
  />
</Helmet>
      <div className="container">
        <div className="text-center my-2">
          <h1 className="title_lg">Word to PDF Converter</h1>
          <p className="mt-1">
            Convert your Word document to a PDF document
          </p>
        </div>
        <div className="uploader-container container">
          {!isConverting && files.length === 0 ? (
            <form className="file-upload-form">
              <label htmlFor="file-upload" className="file-upload-label">
                <div className="file-upload-design">
                  <svg viewBox="0 0 640 512" height="1em">
                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                  </svg>
                  <p>Drag and Drop</p>
                  <p>or</p>
                  <span className="browse-button">Browse File</span>
                </div>
                <input
                  type="file"
                  accept=".docx"
                  id="file-upload"
                  multiple
                  onChange={handleFileChange}
                />
              </label>
            </form>
          ) : (
            <>
              <div className="file-info">
                {files.length > 0 &&
                  files.map((file) => (
                    <p key={file.name}>
                      <strong>{file.name}</strong>
                    </p>
                  ))}
              </div>
              <button
                className="normal-button"
                onClick={handleConvert}
                disabled={isConverting}
              >
                Convert to PDF
              </button>
              {convertedBlob && (
                <button
                  className="buttonDownload my-1"
                  onClick={handleDownload}
                >
                  Download PDF
                </button>
              )}
              <button onClick={handleReset} className="Reset-button">
                Reset
              </button>
            </>
          )}
          {error && <p className="error">{error}</p>}
          {isConverting && <Loader />}
        </div>
        <Instructions description=' This tool allows you to convert your Word files to PDF format. Simply
          upload your Word document below, and after conversion, download the
          file in PDF format.' title="Word to PDF" step1="Upload the Word document you want to convert to PDF." step2="Click Convert to process the document." step3=" Download your document in PDF format after the conversion." />
      </div>
    </>
  );
};

export default WordToPdfConverter;
