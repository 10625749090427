import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import Loader from "./Loader";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import CopyButton from "./CopyButton";
import OutputDisplay from "./OutputDisplay";
const messageStyle = {
   
  padding: "10px",
  borderRadius: "10px",
  maxWidth: "70%",
  marginBottom: "10px",
  alignSelf: "flex-start",
};
const SentenceCompleter = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const [input, setInput] = useState("");
  const [paragraphs, setParagraphs] = useState(6);
  const [educationLevel, setEducationLevel] = useState("Formal");
  const [isLoading, setIsLoading] = useState(false);
  const outputRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };
  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Complete the sentence where incomplete sentence is: "${input}" `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; 
      const sentencesPerParagraph = 10; 
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };
  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2); 
    setEducationLevel("Formal"); 
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>Sentence Completer - Complete Your Half Sentences Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/SentenceCompleter" />
  <meta
    name="description"
    content="Complete your half sentences online. Perfect for filling in missing ideas and structuring sentences."
  />
  <meta
    name="keywords"
    content="sentence completer, complete sentences, sentence finisher, sentence structure tool, online sentence completion, finish incomplete sentences, writing assistance, sentence construction aid, enhance writing, improve sentence structure, complete your thoughts, text enhancement tool, sentence generator, writing tool, sentence improvement, articulate ideas, coherent sentence creation, grammar tool, online writing help, phrase completer, content creation, writing support, enhance clarity, online text tool, completion tool for sentences, express your ideas, structured writing, refine your sentences, writing perfection, online grammar assistant, sentence clarity, creative writing tool, fill in the blanks, sentence elaborator, sentence synthesizer, complete your ideas, writing prompts, improve expression, enhance communication, sentence organization, articulate thoughts, complete partial sentences."
  />
</Helmet>



    <div className="text-center">
        <h1 className="title_lg">Sentence Completer</h1>
        <p className="mt_5">Complete your incomplete sentences</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Sentence</h4>
              <p>What's your incomplete sentence?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/100
                  </span>
                </div>
                <textarea
                 ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder=""
                  rows="15"
                  cols="100"
                  maxLength="100"
                  id="prompt"
                  className="form-control"
                ></textarea>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
          <Instructions
  description="This tool helps you complete sentences or thoughts based on your input. Start typing your sentence, and the tool will suggest completions."
  title="Complete Your Sentences"
  step1="Begin typing your sentence in the input field."
  step2="The tool will analyze your input and suggest ways to complete your sentence."
  step3="Choose the completion that fits your needs best and use it in your writing."
/>
      </div>
    </>
  );
};

export default SentenceCompleter;
