import React from 'react';
import Loader from './Loader';
import CopyButton from './CopyButton';

const OutputDisplay = ({
  messages,
  currentIndex,
  isLoading,
  regenerateText,
  handlePrevious,
  handleNext,
  handleDownload,
  handleCopy,
}) => {
  const messageStyle = {
    padding: "10px",
    borderRadius: "10px",
    marginBottom: "10px",
    alignSelf: "flex-start",
  };

  return (
    <div className="col-12 col-lg-8 col-xl-8">
      <div className="summarizer_item is-disabled">
        <div className="summarizer_item-header">
          <div>
            <h4 className="title">Generated Output</h4>
            <p>Here is your generated output by AI</p>
          </div>
        </div>
        <div className="form-group">
          <div className="summernote-container">
            <div className="note-editor note-frame card">
              <div className="note-toolbar card-header" role="toolbar">
                <div className="note-btn-group btn-group note-mybutton">
                  <button
                    type="button"
                    className="note-btn btn btn-light btn-sm note-btn-rewrite"
                    aria-label="Rewrite"
                    onClick={regenerateText}
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    className="note-btn btn btn-light btn-sm"
                    onClick={handlePrevious}
                    disabled={messages.length === 0 || currentIndex === 0}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="note-btn btn btn-light btn-sm"
                    onClick={handleNext}
                    disabled={
                      messages.length === 0 ||
                      currentIndex === messages.length - 1
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
              <div className="note-editing-area">
                <div
                  className="note-editable card-block"
                  role="textbox"
                  style={{
                    height: "380px",
                    maxHeight: "380px",
                    overflowY: "auto",
                  }}
                >
                  {isLoading ? (
                    <div className="loader-container">
                      <Loader />
                    </div>
                  ) : (
                    messages.length > 0 &&
                    currentIndex !== -1 && (
                      <div style={messageStyle}>
                        {messages[currentIndex].content}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="summarizer_action">
          <button
            className="buttonDownload mt-1"
            onClick={handleDownload}
            disabled={messages.length === 0}
          >
            Download
          </button>
          <CopyButton handleCopy={handleCopy} />
        </div>
      </div>
    </div>
  );
};

export default OutputDisplay;
