import React, { useState, useEffect } from 'react';
const CookieConsent = ({
    message = "This website uses cookies to improve your experience.",
    acceptText = "Accept All",
    declineText = "Decline All",
    preferencesText = "Manage Preferences",
    declineButtonColor = "#f44336",
    policyLink = "toolsbag.io/PrivacyPolicy",
    expirationDays = 365
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [cookieSettings, setCookieSettings] = useState({
        necessary: true,
        analytics: false,
        marketing: false
    });
    useEffect(() => {
        const cookieConsent = JSON.parse(localStorage.getItem('cookieConsent'));
        if (!cookieConsent) {
            setIsVisible(true);
        }
    }, []);
    const handleAcceptAll = () => {
        setCookieConsent({
            necessary: true,
            analytics: true,
            marketing: true
        });
        setIsVisible(false);
    };
    const handleDeclineAll = () => {
        setCookieConsent({
            necessary: true,
            analytics: false,
            marketing: false
        });
        setIsVisible(false);
    };
    const setCookieConsent = (preferences) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + expirationDays);
        const cookieData = { preferences, expirationDate: expirationDate.toISOString() };
        localStorage.setItem('cookieConsent', JSON.stringify(cookieData));
    };
    const togglePreferences = () => {
        setShowPreferences(!showPreferences);
    };
    const handleSavePreferences = () => {
        setCookieConsent(cookieSettings);
        setIsVisible(false);
    };
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCookieSettings(prev => ({ ...prev, [name]: checked }));
    };
    if (!isVisible) return null;
    return (
        <div className="cookie-consent" >
            <p>
                {message}{" "}
                <a href={policyLink} target="_blank" rel="noopener noreferrer" >
                    Learn more
                </a>
            </p>
            <div className="cookie-buttons">
                <button onClick={handleAcceptAll} className='normal-button'>
                    {acceptText}
                </button>
                <button className="Reset-button" onClick={handleDeclineAll} style={{ backgroundColor: declineButtonColor }}>
                    {declineText}
                </button>
                <button className="preferences" onClick={togglePreferences}>
                    {preferencesText}
                </button>
            </div>
            {showPreferences && (
                <div className="cookie-modal mb-10" >
                    <strong>Manage Cookie Preferences</strong>
                    <label>
                        <input
                            type="checkbox"
                            name="necessary"
                            checked={cookieSettings.necessary}
                            disabled
                        />
                        Necessary (Always enabled)
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="analytics"
                            checked={cookieSettings.analytics}
                            onChange={handleCheckboxChange}
                        />
                        Analytics
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="marketing"
                            checked={cookieSettings.marketing}
                            onChange={handleCheckboxChange}
                        />
                        Marketing
                    </label>
                    <div>
                        <button onClick={handleSavePreferences} className="normal-button">
                            Save 
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CookieConsent;
