import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";
  
const BlogOutline = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const outputRef = useRef(null);
  const [input, setInput] = useState("");
  const [paragraphs, setParagraphs] = useState(6);
  const [educationLevel, setEducationLevel] = useState("Formal");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };
  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Write blog Outline for topic: "${input}" `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; 
      const sentencesPerParagraph = 10;
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });

      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2); 
    setEducationLevel("Formal");
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>Blog Outline Writer - Generate Blog Outlines Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/BlogOutline" />
  <meta
    name="description"
    content="Easily generate outlines for your blog posts online. Perfect for organizing ideas, improving content structure, and streamlining the writing process."
  />
  <meta
    name="keywords"
    content="blog outline writer, blog outline generator, create blog outline, write blog outline, blog outline tool, online blog outline creator, blog structure generator, blog post outline, outline creator for blogs, free blog outline generator, blog planning tool, outline writing tool, generate blog structure, organize blog ideas, blog content planner, streamline writing process, outline maker for blog, digital blog outline generator, content organization tool, online content outline, blog framework generator, blog article outline, structure blog posts, outline design for blogs, blog writing assistance, outline ideas for blogs, plan blog content, blog writing strategy, blog planning resource, blog outline software, efficient blog writing, blog topic outline, online writing tools, enhance blog writing, outline suggestions for blogs, creative blog outline maker, blog outline template, organize writing thoughts, blog outline planner, structured blog writing, blog brainstorming tool, blog ideas organizer, blog writing guide, simplify blog structure, digital outline generator, customizable blog outline, blog content organizer, blog project planning, outline tools for bloggers, effective blog outline, blogging efficiency, blog development tool, outline strategy for blogs, plan blog articles, online brainstorming for blogs, blog management tool, outline method for blogging, blog topic planning tool, create structured blog posts, organized writing for blogs, blog outline app, enhance writing productivity, blog development resources, blog outline checklist, online blog planning, blog topic brainstorming, streamline blog content creation, outline guide for bloggers, user-friendly blog outline tool, online blog management, blog writing support, outline templates for blog posts, blog content mapping, structure ideas for blogs, blogging tools and resources, effective blog planning, online blog outline helper."
  />
</Helmet>


    <div className="text-center">
        <h1 className="title_lg">Blog Outline writer</h1>
        <p className="mt_5">This will write you outlines for your blogs</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Your Blog</h4>
              <p>For which blog you want outline?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">0/100</span>
                </div>
                <textarea
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="Enter your blog title here"
                  rows="15"
                  cols="100"
                  maxLength="100"
                  className="form-control"
                ></textarea>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
      </div>
      <Instructions
  description="This tool helps you create structured outlines for your blog posts. Input your blog topic, and the tool will generate a detailed outline to guide your writing."
  title="Create a Blog Outline"
  step1="Enter the topic of your blog post in the input field."
  step2="The tool will generate a structured outline based on your topic, including main points and subtopics."
  step3="Review the outline, make any necessary adjustments, and use it as a guide for your writing."
/>
</>
  );
};

export default BlogOutline;
