import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";
const BlogPostIdeas = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef1 = useRef(null);
  const textAreaRef2 = useRef(null);
  const outputRef = useRef(null);
  const [input, setInput] = useState("");
  const [input1, setInput1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };

  const handleChange1 = (event) => {
    const inputValue = event.target.value;
    setInput1(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter1"
    ).innerText = `${characterCount}/100`;
  };

  const clickHandle = async () => {
    if (!input || !input1) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Give 5 blog post ideas on the topic of "${input}" for "${input1}" audience.`;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const tokensPerIdea = 50; 
      const totalTokens = tokensPerIdea * 15;

      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiContent = chatCompletion.choices[0].message.content;
      const ideas = aiContent.split("\n").filter((idea) => idea.trim() !== "");
      const aiMessages = ideas.map((idea) => ({
        role: "assistant",
        content: idea.trim(),
      }));
      setMessages([...messages, ...aiMessages]);
      setCurrentIndex(messages.length); 
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput1("");
    setInput("");
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };
  const handleCopy = () => {
    if (navigator.clipboard && textAreaRef1.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>
    Blog Post Ideas Generator - Generate Blog Post Ideas Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/AiTools/BlogpostIdeas"
  />
  <meta
    name="description"
    content="Generate blog post ideas online. Perfect for discovering creative and engaging topics for your blog."
  />
  <meta
    name="keywords"
    content="blog post ideas generator, blog ideas, generate blog topics, blog post inspiration, blog topic ideas, creative blog ideas, writing prompts for blogs, blog content generator, online blog ideas tool, discover blog topics, blog brainstorming tool, ideas for blog posts, unique blog ideas, content ideas for blogs, fresh blog post ideas, engaging blog topics, blog theme generator, write a blog post idea, blog subject ideas, online brainstorming tool, creative writing for blogs, blog planning tool, inspiration for blog posts, niche blog ideas, blog topic inspiration, article ideas generator, generate content ideas, blog post suggestion tool, content creation ideas, trending blog topics, captivating blog post ideas, blog post themes, blog topic generator, unique content ideas, discover new blog ideas, write engaging blog content, blog writing inspiration, blogging ideas tool, effective blog ideas, innovative blog topics, popular blog ideas, actionable blog topics, blog content planning tool, brainstorm blog ideas, blog content inspiration, blog topic brainstorming, write compelling blog ideas, useful blog topics, find blog ideas online, intriguing blog post topics, engaging topics for blogs, online content brainstorming, quick blog ideas, best blog ideas generator, unique blog themes, compelling blog content ideas, blog headline ideas"
  />
</Helmet>
      <div className="text-center">
        <h1 className="title_lg">Blog Post ideas generator</h1>
        <p className="mt_5">Generate Blog Post Ideas</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Make Content Short</h4>
              <p>What's your content you want to make Short?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/100
                  </span>
                </div>
                <p>Topic of your Blog post:</p>
                <textarea
                  ref={textAreaRef1}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange}
                  placeholder="e.g. Software developer"
                  rows="4"
                  cols="100"
                  maxLength="100"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter1" className="counter">
                    0/100
                  </span>
                </div>
                <p>Target Audience:</p>
                <textarea
                  ref={textAreaRef2}
                  onChange={handleChange1}
                  placeholder="e.g. Software developer"
                  rows="4"
                  cols="100"
                  maxLength="100"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>

              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
        <Instructions
          description="  This tool helps you generate creative and engaging blog post ideas based on the topics or themes you provide. Whether you're stuck on what to write about or need inspiration for your next blog post, simply enter a keyword or topic, and the tool will generate fresh ideas for you."
          title="Generate Blog Post Ideas"
          step1="Enter a topic, keyword, or theme related to your niche in the provided input field."
          step2="Click the Generate Ideas button to receive a list of potential blog post ideas."
          step3="Use the ideas as inspiration to start writing your blog post, or generate more ideas for additional inspiration!"
        />
      </div>
    </>
  );
};

export default BlogPostIdeas;
