import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import Loader from "./Loader";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import CopyButton from "./CopyButton";
const messageStyle = {
  padding: "20px",
  borderRadius: "10px",
  maxWidth: "100%",
  marginBottom: "10px",
  alignSelf: "flex-start",
};
const BusinessSlogan = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const textAreaRef1 = useRef(null);
  const textAreaRef2 = useRef(null);
  const [input1, setInput1] = useState("");
  const [input, setInput] = useState("");
  const outputRef = useRef(null);
  const [paragraphs, setParagraphs] = useState(6);
  const [educationLevel, setEducationLevel] = useState("Formal");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };

  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Generate the business slogan for business and name of business is: "${input}" and this business "${input1}" do `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });

      const userMessage = { role: "user", content: prompt };

      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });

      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };

      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChange1 = (event) => {
    const inputValue = event.target.value;
    setInput1(inputValue);
    const characterCount = inputValue.length;
    document.getElementById("character-counter1").innerText = `${characterCount}/100`;
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2);
    setEducationLevel("Formal");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>Business Slogan Writer - Generate Catchy Slogans Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/BusinessSlogan" />
  <meta
    name="description"
    content="Easily generate catchy and creative business slogans online. Ideal for branding, marketing campaigns, and business promotions."
  />
  <meta
    name="keywords"
    content="business slogan writer, slogan generator, create business slogan, write slogan online, catchy business slogans, generate slogans, online slogan creator, business tagline maker, marketing slogan generator, catchy taglines, unique slogans, branding slogans, brand name ideas, catchy phrases, creative slogans, advertising slogans, promotional slogans, slogan ideas, business branding, memorable slogans, innovative slogans, online branding tool, marketing tool, slogan writing, business marketing slogans, catchy marketing phrases, effective slogans, business catchphrases, slogan maker, tagline generator, create catchy taglines, impactful slogans, slogan ideas for businesses, catchy business phrases, business identity slogans, short slogans, attention-grabbing slogans, brand messaging, custom slogans, slogan development, digital slogan creator, slogan design, slogan inspiration, quick slogan generator, brainstorming slogans, catchy business taglines, smart slogans, online slogan writing, compelling slogans, business motto generator, effective brand slogans, slogan crafting, creative tagline ideas, promotional tagline generator, user-friendly slogan tool, digital marketing slogans, catchy words for slogans, slogan concepts, innovative tagline generator, powerful slogans, brand recognition slogans, advertising taglines, brand loyalty slogans, appealing slogans, service slogans, business promotion ideas, catchy company slogans, slogan suggestions, simple slogan generator, slogan brainstorming tool, catchy phrases for branding, fresh slogans, engaging slogans, advertising campaigns, promotional ideas, marketing catchphrases, catchy phrases for businesses, attention-grabbing taglines, brand communication slogans."
  />
</Helmet>


    <div className="text-center">
        <h1 className="title_lg">Business Slogan writer</h1>
        <p className="mt_5">This will suggest you business slogans for your business</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Your Business</h4>
              <p>For which business you want slogan?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">0/100</span>
                </div>
                <p>Name of your business:</p>
                <textarea
                  ref={textAreaRef1}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange}
                  placeholder="e.g. Best sellers"
                  rows="5"
                  cols="100"
                  maxLength="100"
                  className="form-control"
                ></textarea>
              </div>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter1" className="counter">0/100</span>
                </div>
                <p>What's your business do?</p>
                <textarea
                  ref={textAreaRef2}
                  onChange={handleChange1}
                  placeholder="e.g. Sell books"
                  rows="4"
                  cols="100"
                  maxLength="100"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
      </div>
      <Instructions
  description="This tool helps you generate catchy and creative slogans for your business. Enter your business name and a brief description, and the tool will provide you with unique slogan ideas."
  title="Create a Business Slogan"
  step1="Enter your business name in the input field."
  step2="Provide a brief description of your business and its values."
  step3="Once you submit, the tool will generate a list of unique slogan ideas for you to choose from."
/>
</>
  );
};

export default BusinessSlogan;
