import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import { useLocation } from "react-router-dom"; 
import { Helmet } from 'react-helmet';
import Instructions from './Instructions';

const RoundCropper = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImg, setCroppedImg] = useState(null);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const onCropCompleteInternal = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImg = (imageSrc, croppedAreaPixels, shape = 'circle') => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const size = Math.min(croppedAreaPixels.width, croppedAreaPixels.height);
        canvas.width = size;
        canvas.height = size;

        if (shape === 'circle') {
          ctx.beginPath();
          ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
          ctx.clip();
        }

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          size,
          size
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          const croppedImageUrl = URL.createObjectURL(blob);
          resolve(croppedImageUrl);
        }, 'image/png');
      };
      image.onerror = reject;
    });
  };

  const handleCropImage = async () => {
    if (!croppedAreaPixels || !imageSrc) {
      console.error('Cropped area pixels or image source is invalid.');
      return;
    }
    try {
      const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels, 'circle');
      setCroppedImg(croppedImg);
    } catch (e) {
      console.error('Error cropping image:', e);
    }
  };

  const handleDownloadCroppedImage = () => {
    if (!croppedImg) return;
    const link = document.createElement('a');
    link.href = croppedImg;
    link.download = 'cropped-circle-image.png';
    link.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result); 
      };
      reader.readAsDataURL(file);
    }
  };

  const handleReset = () => {
    setImageSrc(null);
    setCroppedImg(null);
    setCroppedAreaPixels(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  return (
    <>
      <Helmet>
  <meta charSet="utf-8" />
  <title>Round Image Cropper - Create Profile Pictures Easily</title>
  <link rel="canonical" href="http://toolsbag.io/ImageTools/roundImageCropper" />
  <meta
    name="description"
    content="Easily crop images into round shapes for profile pictures. Upload your images and create perfect profile pics with our intuitive online tool."
  />
  <meta
    name="keywords"
    content="
      round image cropper, profile picture maker, crop images online, image cropping tool, circular cropper, avatar maker, photo editor, image resizer, photo crop tool, online image cropping, circular image cropper, create round profile picture, profile picture tool, crop image to circle, image manipulation, circular photo editor, upload image, image uploader, image processing, resize image, photo enhancement, image adjustments, profile pic generator, online photo tools, create avatar, avatar generator, circular cropping tool, image crop and download, picture resizer, round image editor, profile image cropper, online avatar maker, image quality enhancement,  easy photo cropping, intuitive crop tool, crop images for social media, profile image resizing, create circular images, crop to fit, round cropping solution, photo adjustment tools, image editing solutions, online image editor, quick photo cropper, circular image editing, simple image cropper, profile image customizer, advanced cropping features, cropper for profile pictures, online graphic design tool, circular cropper online, user-friendly crop tool, crop images for websites, crop photos quickly, high-quality image cropping, round photo creator, circular crop and download, 
      customizable image cropper, seamless photo editing, quick image adjustments, online image tools, enhance profile pictures, professional photo cropping, crop images for portfolios, user-friendly avatar generator, online photo customizer, circular photo generator, create unique profile images, enhance avatar quality, customizable profile picture maker, user-friendly editing interface, quick and easy image cropping, round image creation tool, optimized image cropping, online cropping solutions, efficient image processing, simple photo editing, crop images for dating apps, unique avatar creation, image cropping for professionals, profile image tool, crop for social networks, versatile image cropping, 
      quick and easy photo adjustments, round shape image cropper, online design tools, advanced image cropping techniques, circular image transformations, seamless profile image creation, online cropping services, create stunning avatars, professional image editing, user-friendly photo tools, fast and easy image cropping
    "
  />
</Helmet>


      <div className="container">
        <div className="text-center">
          <h1 className="title_lg">Profile Picture Maker</h1>
          <p className="mt-1">Crop your images round and make your profile pictures</p>
        </div>
        
        {!imageSrc && (
          <div className="uploader-container text-center">
            <form className="file-upload-form">
              <label htmlFor="file" className="btn btn-primary">
                <div className="file-upload-design">
                  <svg viewBox="0 0 640 512" height="1em">
                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                  </svg>
                  <p className="mb-0">Drag and Drop or Browse file</p>
                </div>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  className="d-none"
                  onChange={handleFileChange}
                />
              </label>
            </form>
          </div>
        )}

        {imageSrc && (
          <div className="cropper-container my-5">
            <div className="cropper-wrapper mb-3" style={{ height: '400px', position: 'relative' }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                showGrid={false}
                onCropChange={onCropChange}
                onCropComplete={onCropCompleteInternal}
                onZoomChange={onZoomChange}
                style={{
                  containerStyle: {
                    height: '100%',
                    width: '100%',
                  },
                  mediaStyle: {
                    objectFit: 'contain',
                  },
                }}
              />
            </div>
            <div className="controls text-center">
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(e, value) => onZoomChange(value)}
                className="mb-3"
              />
              <div className="btn-group">
                <button className="btn normal-button mx-2" onClick={handleCropImage}>
                  Crop
                </button>
                <button 
                  className="btn btn-success mx-2" 
                  onClick={handleDownloadCroppedImage} 
                  disabled={!croppedImg}
                >
                  Download
                </button>
                <button className="btn Reset-button mx-2" onClick={handleReset}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Instructions
        description="This tool allows you to crop images into a perfect round shape. Simply upload your image below, adjust the crop as needed, and download the circular version."
        title="Crop Your Image into a Round Shape"
        step1="Upload the image you want to crop into a round shape."
        step2="Adjust the cropping area to fit the round shape you want."
        step3="Click on 'Crop' to create the round image, then download it."
      />
    </>
  );
};

export default RoundCropper;
