import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import { SearchProvider } from './components/SearchContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SearchProvider>
  <React.StrictMode>
  <Auth0Provider
    domain="dev-3tg4xrs77svs7db3.us.auth0.com"
    clientId="EaAKHPvnyNuFtXyNK0XVdJdiwATmn9Ih"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <App />
  </Auth0Provider>
  </React.StrictMode>
  </SearchProvider>
);
reportWebVitals();
// registerServiceWorker();
