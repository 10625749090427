import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";
const educationLevels = ["Formal", "Informal", "Assertive","Optimistic","Humorous","Pessimistic","Friendly","Motivating","Serious","Amused","Curious","Sadness","Absurd","Accusatory","Acerbic","Aggression","Apathetic","Enthusiastic","Information","Arrogant","Belligerent",];
const ParagraphWriter = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const [input, setInput] = useState("");
  const [paragraphs, setParagraphs] = useState(6);
  const [educationLevel, setEducationLevel] = useState("Formal");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const outputRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };
  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Write paragraph on this topic: "${input}" according to the tone like "${educationLevel}" having "${paragraphs}" no of lines`;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; 
      const sentencesPerParagraph = 10; 
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleParagraphsChange = (event) => {
    const newValue = parseInt(event.target.value);
    setParagraphs(isNaN(newValue) ? 0 : newValue);
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };
  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2);
    setEducationLevel("Formal"); 
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleEducationLevelChange = (event) => {
    setEducationLevel(event.target.value);
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>
    Paragraph Writer - Write Any Type of Paragraph Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/AiTools/ParagraphWriter"
  />
  <meta
    name="description"
    content="Write any type of paragraph online. Perfect for generating well-structured paragraphs on any topic."
  />
  <meta
    name="keywords"
    content="paragraph writer, write paragraphs online, paragraph generator, paragraph creation tool, online paragraph writer, paragraph writing tool, generate paragraphs, create paragraphs, structured paragraphs, content writing, academic paragraphs, creative writing, essay paragraphs, paragraph assistance, effective paragraphs, write better paragraphs, professional paragraph writing, paragraph editor, online writing assistant, enhance writing skills, text generation, articulate paragraphs, coherent paragraphs, well-structured writing, writing services, quick paragraph writing, engaging paragraphs, simple paragraphs, complex paragraphs, persuasive paragraphs, descriptive paragraphs, informative paragraphs, narrative paragraphs, writing tips, writing prompts, paragraph development, paragraph crafting, online text tool, content generator, text structuring, fluent paragraphs, customized paragraphs, writing accuracy, language skills, concise paragraphs, clear writing, academic writing tools, digital writing assistant, organized paragraphs, polished writing, improve writing quality, text improvement, skillful writing, dynamic writing tool, writing creativity, focused writing, thorough writing, crafting paragraphs online, generate ideas, effective communication, paragraph formatting, sentence variety, logical flow, articulate ideas, structured content, creative paragraph writing, precise writing, text enhancement, targeted writing, productive writing, writing resources, efficient writing, complete paragraphs, original paragraphs, insightful writing, effective expression, fluent writing, tailored writing solutions, cohesive writing, audience engagement, structured ideas, collaborative writing, personalized writing, user-friendly writing, adaptive writing tools, engaging content creation, writing guidance, focused paragraphs, comprehensive writing, robust writing tools, clear communication, targeted content, articulate your thoughts, contextual writing,
     paragraph organization, effective narrative, academic essays, concise communication, thematic writing, imaginative writing, polished content, impactful paragraphs, thoughtful writing, specific topics, idea development, focused essays, writing skills enhancement, textual coherence, seamless transitions, well-organized writing, adaptable paragraphs"
  />
</Helmet>


    <div className="text-center">
        <h1 className="title_lg">Paragraph writer</h1>
        <p className="mt_5">Write Your paragraph</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Paragraph Topic</h4>
              <p>What's your Paragraph about?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/100
                  </span>
                </div>
                <textarea
                 ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="Enter your paragraph topic"
                  rows="6"
                  cols="100"
                  maxLength="100"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="number_answers">Number of Lines</label>
                <input
                  type="number"
                  className="form-control my-2"
                  value={paragraphs}
                  onChange={handleParagraphsChange}
                  placeholder="Number of paragraphs"
                />
              </div>
              <div className="form-group">
                <label htmlFor="education_level">Tone of voice</label>
                <select
                  className="form-control my-2"
                  value={educationLevel}
                  onChange={handleEducationLevelChange}
                >
                  {educationLevels.map((level, index) => (
                    <option key={index} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
        <Instructions
          description="This tool helps you generate well-structured and coherent paragraphs based on a given topic or idea. Whether you're writing an article, essay, or any form of content, simply input your topic, and the tool will generate a complete paragraph to help you get started or enhance your writing."
          title="Write a Paragraph for Your Content"
          step1="Enter a topic, idea, or sentence that you want to expand into a full paragraph."
          step2="Click the Write Paragraph button to generate a detailed paragraph based on your input, and review it and regenerate if needed."
          step3="  Use the paragraph in your content and repeat the process to generate additional paragraphs or refine existing ones."
        />
      </div>
    </>
  );
};

export default ParagraphWriter;
