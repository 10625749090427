import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
export default function About(props) {
  return (<>
  <Helmet>
  <meta charSet="utf-8" />
  <title>
    About Us - Learn More About Toolsbag.io and Our AI Tools
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/about"
  />
  <meta
    name="description"
    content="Learn more about Toolsbag.io, our mission, and how we are helping people enhance their work with AI-powered tools."
  />
  <meta
    name="keywords"
    content="about toolsbag.io, about us, AI tools platform, our mission, about AI productivity tools, toolsbag.io overview, AI technology, innovative tools, online productivity, enhancing work with AI, AI applications, technology solutions, user-focused design, digital tools for productivity, efficiency tools, AI-powered solutions, mission-driven platform, empowering users, online resource for AI tools, tools for businesses, creative AI tools, AI writing tools, productivity enhancements, toolsbag.io services, transforming work with AI, user support, AI for professionals, making work easier with AI, tools for content creation, AI solutions for businesses, improving workflow with AI, digital transformation, modern productivity tools, online AI tools, user-friendly platform, cutting-edge technology, tools for writers, content optimization tools, data-driven solutions, toolsbag.io features, maximizing productivity with AI, online tools for efficiency, AI for everyday tasks, innovative technology solutions, tools for marketers, enhancing creativity with AI, AI-assisted tools, collaborative tools, cloud-based solutions, productivity software, toolsbag.io advantages, advanced AI features, enhancing productivity online, tools for entrepreneurs, 
    AI tool integration, tools for small businesses, online productivity solutions, toolsbag.io benefits, future of work with AI, AI-powered applications, supporting digital workspaces, creating with AI, user engagement, tools for designers, personalized AI tools, tools for research, technology for creatives, AI-driven productivity, enhancing online presence, tools for social media, AI assistance in work, tools for education, maximizing creativity with AI, supporting users in their work, building a better work experience, innovative AI features, tools for all industries, AI innovation, next-generation productivity tools, tools for project management, AI technology for everyone, user-centered AI tools, tools for effective communication, automating tasks with AI, AI in daily work, toolsbag.io community, technology for professionals, AI tools for writers, tools for enhancing learning, tools for time management, tools for improved focus, tools for content strategists, collaborative technology, tools for data analysis, enhancing teamwork with AI, tools for better decision-making, tools for streamlined workflows, technology for enhancing productivity, tools for content marketers, AI-driven solutions for all, creating content with AI, technology for smarter work, online resources for productivity, tools for effective collaboration"
  />
</Helmet>

    <div
      className="container"
    >
      <div className="text-center">
        <h1 className="title_lg">About us</h1>
        <p className="mt-3 my-3">
          Welcome to <strong>Tools Bag</strong>, your one-stop destination for
          innovative digital tools designed to simplify and enhance your
          everyday tasks. Whether you're managing PDF files, working with
          images, or exploring the power of AI, our platform offers a range of
          efficient and easy-to-use solutions.
        </p>
      </div>

      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <strong>Our Mission</strong>
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              At <strong>Tools Bag</strong>, we aim to empower individuals and
              businesses by providing tools that save time, improve productivity,
              and unlock creativity. From PDF management tools that allow seamless
              document conversions and edits, to advanced image manipulation
              software and AI-driven innovations, we are here to help you succeed.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <strong>What We Offer</strong>
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <ul>
                <li>
                  <strong>PDF Tools:</strong> Convert, merge, split, compress, and edit PDF files with ease.
                </li>
                <li>
                  <strong>Image Tools:</strong> Enhance, resize, and optimize images for all your needs, whether personal or professional.
                </li>
                <li>
                  <strong>AI Tools:</strong> Experience the future with AI-driven solutions for automating tasks, generating content, and more.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <strong>Why Choose Us?</strong>
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" >
              We believe that digital tools should be accessible to everyone. Our platform is designed to be user-friendly, fast, and reliable, offering a seamless experience whether you're a casual user or a professional.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <strong>Our Vision</strong>
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" >
              Our vision is to become a global leader in digital tool solutions by continuously evolving with the latest technologies, making complex tasks simpler for everyone, from individuals to large enterprises.
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              <strong>Free to Use</strong>
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" >
              We are committed to keeping our tools completely free for everyone. You can use all the features of our platform without any hidden costs or subscription fees. Whether you're a student, professional, or just someone looking for quick file management solutions, we've got you covered. <strong>"Our goal is to help you work smarter, not harder, and make everyday digital tasks a breeze!"</strong>
            </div>
          </div>
        </div><div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button
              className="accordion-button collapsed"
              type="button"
              
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              <strong>Get In Touch</strong>
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" >
            Have any questions or need support? We are here to help! Feel free to reach out to us through our <strong style={{textDecoration:"underline"}}><Link to='/contact-Us'>Contact Us</Link></strong> and let us know how we can assist you.
            </div>
          </div>
        </div>
      </div>
    </div></>
  );
}
