import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import Instructions from "./Instructions";
import OutputDisplay from "./OutputDisplay";

const BusinessName = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const [input, setInput] = useState("");
  const outputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };
  const clickHandle = async () => {
    if (!input ) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Generate 2 business names in bullets and the business is about: "${input}" `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });

      const userMessage = { role: "user", content: prompt };

      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph ;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });

      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };

      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
      // setInput("");
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };


  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
      <Helmet>
  <meta charSet="utf-8" />
  <title>
    Business Name Writer - Generate Unique Business Names Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/AiTools/Businessname"
  />
  <meta
    name="description"
    content="Easily generate unique and creative business names online. Perfect for startups, branding, and new ventures."
  />
  <meta
    name="keywords"
    content="business name writer, business name generator, create business name, company name generator, brand name ideas, unique business names, creative business names, catchy business names, startup name ideas, business naming tool, name ideas for businesses, brand naming service, online business name generator, custom business names, innovative brand names, professional business names, name creator for businesses, name suggestions for startups, small business names, naming your business, business identity creation, memorable business names, unique brand identities, business name ideas, branding and naming, impactful business names, easy business name generator, domain name generator, brand strategy, creative naming solutions, effective business names, naming strategies for startups, brand development, quick business name ideas, personalized business names, original business names, brand name maker, domain name suggestions, unique company names, trendy business names, descriptive business names, appealing business names, brainstorming business names, effective branding, meaningful business names, catchy brand names, concise business names, simple business names, business name brainstorming tool, brand name development, clever business names, sophisticated business names, memorable brand names, innovative business naming, trendy brand names, industry-specific business names, adaptable business names, distinctive business names, engaging business names, suitable business names, creative naming ideas, brand positioning, business name inspirations, standout business names, online brand generator, startup branding solutions, quick name generation, digital business naming tool,
     name your brand, compelling business identities, unique identifiers for businesses, online branding strategies, accessible business names, domain availability checks, user-friendly name generator, professional branding tools, customizable business names, marketable business names, name validation, innovative naming ideas, exploratory business naming, effective naming practices, effective naming techniques, service-based business names, dynamic business names, flexible branding solutions, original brand creation, brand visioning, comprehensive name ideas, resourceful business naming, relevant business names, sustainable business names, online name creation, unique identifiers, business name analysis, brand name research, unique naming concepts, social media handle generator, catchy slogans and names, memorable company identifiers, personalized brand suggestions, startup name creator, conceptual business names, innovative name generation, name evaluation, comprehensive brand naming, user-driven naming processes, research-based name generation, market-oriented business names, impactful brand identifiers, distinctive identifiers, functional business names, name variations, diverse business naming strategies, creative naming practices, business identity enhancement, memorable marketing names, targeted business naming, engaging brand narratives, innovative naming methodologies, streamlined name generation, compelling company names, contextual brand names, startup identity creation"
  />
</Helmet>


    <div className="text-center">
        <h1 className="title_lg">Business Name writer</h1>
        <p className="mt_5">This will suggest you business names for your business</p>
      </div>
      <div
        className="summarizer container" 
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">About the Business </h4>
              <p>What's your business about?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/100
                  </span>
                </div>
                <textarea
                 ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="About your business. e.g. selling books "
                  rows="15"
                  cols="100"
                  maxLength="100"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              
              
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <OutputDisplay
          ref={outputRef}
        messages={messages}
        currentIndex={currentIndex}
        isLoading={isLoading}
        regenerateText={regenerateText}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleDownload={handleDownload}
        handleCopy={handleCopy}
      />
        </div>
      </div>
      <Instructions
  description="This tool helps you generate unique and catchy business names. Enter your keywords, and the tool will provide creative name suggestions."
  title="Generate Business Names"
  step1="Enter relevant keywords or themes related to your business in the input field."
  step2="Click on the 'Generate' button to receive a list of creative business name suggestions."
  step3="Review the suggested names and choose the one that resonates best with your vision."
/>
    </>
  );
};

export default BusinessName;
