import React from 'react';
const Loader = (props) => {
  return (
    <div className="loader2">
      <div className="loader-text">{props.text}</div>
      <div className="loader-bar"></div>
    </div>
  );
}

export default Loader;
